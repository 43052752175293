import { Inject, Component, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { RecordsListComponent } from 'app/core/base-classes/records-list-component';
import { Subject, takeUntil } from 'rxjs';

import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppComponent } from '../../../../app.component';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Router } from '@angular/router';
import { UpdateEventComponent } from '../../../../modules/admin/hr-masters/calendar/update-event/update-event.component';
import { STATICS } from '../../../../core/config/static/static';
import { CalendarService } from '../../../../../app/modules/admin/hr-masters/calendar/calendar.service';
@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewDetailsComponent extends RecordsListComponent<any> {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private matDialog: MatDialog,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(AppComponent) private appComponent: AppComponent,
    private dialogRef: MatDialogRef<ViewDetailsComponent>,
    private _notificationsService: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _calenderService: CalendarService
  ) {
    super(appComponent);
    this.getRecordsList();
  }
  getRecordsList() {
    this.showLoader();
    this._notificationsService.getAll(this.recordsFilter).subscribe(
      () => {
        this.updateListPage();
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  getBackgroundStyle(index: number): { [key: string]: string } {
    const colors = ['#D6EBBC', '#EBF5DD', '#F4F4F4'];
    const color = colors[index % colors.length];
    return { 'background-color': color };
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  updateListPage() {
    this._notificationsService.notifications$.pipe(takeUntil(this._unsubscribeAll)).subscribe((response: any) => {
      this.recordsList = response;
      if (this.recordsList.length > 0) {
        for (let index = 0; index < this.recordsList.length; index++) {
          let message = '';
          let requestType = '';
          let depName = '';
          const endDate = new Date(this.recordsList[index].endDate);
          this.recordsList[index]['endDisplayDate'] = endDate;

          // Handle deleted records
          if (this.recordsList[index].isDeleted) {
            depName = this.recordsList[index]?.actionBy?.departmentName ? this.recordsList[index]?.actionBy?.departmentName : '';
            message =
              'Absence request deleted by ' +
              this.recordsList[index].actionBy.lastName +
              ' ' +
              this.recordsList[index].actionBy.firstName +
              ' (' +
              depName +
              ')';
          }
          // Handle other status cases if not deleted
          else if (this.recordsList[index].absenceStatus === 1) {
            if (this.recordsList[index].userDetail._id !== this.data.currentUser.userId) {
              if (this.recordsList[index].userDetail._id !== this.recordsList[index].requestedBy._id) {
                // eslint-disable-next-line max-len
                depName = this.recordsList[index]?.requestedBy?.departmentName ? this.recordsList[index]?.requestedBy?.departmentName : '';
                requestType = this.recordsList[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
                message =
                  requestType +
                    this.recordsList[index].requestedBy.lastName +
                    ' ' +
                    this.recordsList[index].requestedBy.firstName +
                    ' (' +
                    depName +
                    ') for ' +
                    this.recordsList[index].userDetail.lastName +
                    ' ' +
                    this.recordsList[index].userDetail.firstName +
                    ' (' +
                    this.recordsList[index]?.userDetail?.departmentName || '' + ')';
              } else {
                depName = this.recordsList[index]?.requestedBy?.departmentName ? this.recordsList[index]?.requestedBy?.departmentName : '';
                requestType = this.recordsList[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
                message =
                  requestType + this.recordsList[index].requestedBy.lastName + ' ' + this.recordsList[index].requestedBy.firstName + ' (' + depName + ')';
              }
            } else {
              // eslint-disable-next-line max-len
              depName = this.recordsList[index]?.requestedBy?.departmentName ? this.recordsList[index]?.requestedBy?.departmentName : '';
              requestType = this.recordsList[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
              message =
                requestType + this.recordsList[index].requestedBy.lastName + ' ' + this.recordsList[index].requestedBy.firstName + ' (' + depName + ') for you';
            }
          } else if (this.recordsList[index].absenceStatus === 4 || this.recordsList[index].absenceStatus === 6) {
            if (this.recordsList[index].actionTakenForUser._id !== this.data.currentUser.userId) {
              // eslint-disable-next-line max-len
              depName = this.recordsList[index]?.actionBy?.departmentName ? this.recordsList[index]?.actionBy?.departmentName : '';
              message =
                'Absence request rejected by ' +
                this.recordsList[index].actionBy.lastName +
                ' ' +
                this.recordsList[index].actionBy.firstName +
                ' (' +
                depName +
                ') of ' +
                this.recordsList[index].actionTakenForUser.lastName +
                ' ' +
                this.recordsList[index].actionTakenForUser.firstName;
            } else {
              // eslint-disable-next-line max-len
              depName = this.recordsList[index]?.actionBy?.departmentName ? this.recordsList[index]?.actionBy?.departmentName : '';
              message =
                'Your Absence request rejected by ' +
                this.recordsList[index].actionBy.lastName +
                ' ' +
                this.recordsList[index].actionBy.firstName +
                ' (' +
                depName +
                ')';
            }
          } else if (this.recordsList[index].absenceStatus === 5 || this.recordsList[index].absenceStatus === 7) {
            // eslint-disable-next-line max-len
            if (
              this.recordsList[index].actionBy !== undefined &&
              this.recordsList[index].actionBy !== null &&
              this.recordsList[index].actionTakenForUser._id !== this.data.currentUser.userId
            ) {
              // eslint-disable-next-line max-len
              if (this.recordsList[index].actionBy !== undefined && this.recordsList[index].actionBy !== null) {
                depName = this.recordsList[index]?.actionBy?.departmentName ? this.recordsList[index]?.actionBy?.departmentName : '';
                message =
                  'Absence request approved by ' +
                  this.recordsList[index].actionBy.lastName +
                  ' ' +
                  this.recordsList[index].actionBy.firstName +
                  ' (' +
                  depName +
                  ') of ' +
                  this.recordsList[index].actionTakenForUser.lastName +
                  ' ' +
                  this.recordsList[index].actionTakenForUser.firstName;
              } else {
                depName = this.recordsList[index]?.requestedBy?.departmentName ? this.recordsList[index]?.requestedBy?.departmentName : '';
                message =
                  'Absence request approved by ' +
                  this.recordsList[index].requestedBy.lastName +
                  ' ' +
                  this.recordsList[index].requestedBy.firstName +
                  ' (' +
                  depName +
                  ') of ' +
                  this.recordsList[index].userDetail.lastName +
                  ' ' +
                  this.recordsList[index].userDetail.firstName;
              }
            } else {
              if (this.recordsList[index].actionBy !== undefined && this.recordsList[index].actionBy !== null) {
                depName = this.recordsList[index]?.actionBy?.departmentName ? this.recordsList[index]?.actionBy?.departmentName : '';
                message =
                  'Your Absence request approved by ' +
                  this.recordsList[index].actionBy.lastName +
                  ' ' +
                  this.recordsList[index].actionBy.firstName +
                  ' (' +
                  depName +
                  ')';
              } else {
                depName = this.recordsList[index]?.requestedBy?.departmentName ? this.recordsList[index]?.requestedBy?.departmentName : '';
                message =
                  'Your Absence request approved by ' +
                  this.recordsList[index].requestedBy.lastName +
                  ' ' +
                  this.recordsList[index].requestedBy.firstName +
                  ' (' +
                  depName +
                  ')';
              }
            }
          }
          this.recordsList[index]['message'] = message;
        }
      }
      this.totalCount = this._notificationsService.notificationCount$;
      this._changeDetectorRef.markForCheck();
    });
  }

  tabClick(value) {
    if (value.index === 0) {
      delete this.recordsFilter['isRead'];
    } else if (value.index === 2) {
      this.recordsFilter['isRead'] = true;
    } else if (value.index === 1) {
      this.recordsFilter['isRead'] = false;
    }
    this.getRecordsList();
  }

  navigate(notification) {
    this.showLoader();
    if (!notification.isRead) {
      this._notificationsService.markRead(notification._id).subscribe(
        () => {
          //
        },
        (err) => {
          this.showErrorToast(`Error in fetching records ${err.error.message}`);
          this.hideLoader();
        }
      );
    }
    notification.userDetail['userId'] = notification.userDetail?._id;
    this._calenderService.getRequestDetails(notification.absenceId).subscribe(
      (data) => {
        let endDate = new Date(data?.endDate);
        if (data?.isFullDay) {
          endDate = new Date(endDate.setMinutes(endDate.getMinutes() + 330));
        }
        const eventInfo = {
          id: data?._id,
          userId: data?.userId,
          createdBy: data?.createdBy,
          status: data?.absenceStatus,
          allDay: data?.isFullDay,
          title: data?.absenceTypeName,
          titleId: data?.absenceTypeId,
          start: data?.startDate,
          end: endDate,
          actualEndDate: data?.endDate,
          backgroundColor: STATICS.ABSENCE_STATUS.filter((status) => status.status === data?.absenceStatus)[0].color,
          description: data?.reason,
          reason: data?.reason,
          rejectedReason: data?.rejectedReason,
          selectedUser: notification.userDetail,
          managerExists: data?.managerExists,
          logs: data?.logs,
        };
        this.hideLoader();
        this.openEventModal(eventInfo);
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  openEventModal(eventInfo) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '60%',
      height: '80%',
      data: {
        selectInfo: eventInfo,
        currentUser: this.data.currentUser,
        selectedUser: eventInfo.selectedUser,
        isManager: this.data.isManagerHR,
        display: true,
        absenceTypes: this.data.absenceTypes,
      },
      panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
    };
    const dialogRef: MatDialogRef<UpdateEventComponent, any> = this.matDialog.open(UpdateEventComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getRecordsList();
    });
  }
}

export const Constants = {
  countryMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'code', label: 'Code', read: false, hidden: false },
      { field: 'capital', label: 'Capital', read: false, hidden: false },
      { field: 'nationality', label: 'Nationality', read: false, hidden: false },
      { field: 'flag', label: 'Flag', read: false, hidden: false },
      { field: 'diallingCode', label: 'Dialling Code', read: false, hidden: false },
      { field: 'currency', label: 'Currency', read: false, hidden: false },
      { field: 'timeZone', label: 'TimeZone', read: false, hidden: false },
    ],
  },
  officeLocation: {
    listing: [
      { field: 'name', label: 'Office Location', read: false, hidden: false },
      { field: 'countryId', label: 'Country', read: false, hidden: false },
      { field: 'stateId', label: 'State', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Office Location', read: false, write: false, hidden: false },
      { field: 'countryId', schemaKey: 'countryId', label: 'Country', read: false, write: false, hidden: false },
      { field: 'stateId', schemaKey: 'stateId', label: 'State', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  state: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'countryId', label: 'Country', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'countryId', schemaKey: 'countryId', label: 'Country', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  city: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'countryId', label: 'Country', read: false, hidden: false },
      { field: 'stateId', label: 'State', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'countryId', schemaKey: 'countryId', label: 'Country', read: false, write: false, hidden: false },
      { field: 'stateId', schemaKey: 'stateId', label: 'State', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  languageMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'code', label: 'Code', read: false, hidden: false },
      { field: 'isApplicationLanguage', label: 'Is Application Language', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
    ],
  },
  companyTypeMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  companyMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'companyTypeId', label: 'Company Type(For Update Only)', read: false, hidden: false },
      { field: 'address', label: 'Address(For Update Only)', read: false, hidden: false },
      { field: 'domainName', label: 'Domain Name', read: false, hidden: false },
      { field: 'supportedLanguages', label: 'Supported Languages(For Update Only)', read: false, hidden: false },
      { field: 'contactPerson', label: 'Contact Person', read: false, hidden: false },
      { field: 'defaultLanguageId', label: 'Default Language(For Update Only)', read: false, hidden: false },
      { field: 'status', label: 'Status', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'type', schemaKey: 'companyTypeId', label: 'Company Type', read: false, write: false, hidden: false },
      { field: 'defaultLanguage', schemaKey: 'defaultLanguageId', label: 'default Language', read: false, write: false, hidden: false },
      { field: 'supportedLanguage', schemaKey: 'supportedLanguages', label: 'Supported Language', read: false, write: false, hidden: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, write: false, hidden: false },
    ],
    details: [
      { field: 'groupName', label: 'Group Name', read: false, hidden: false },
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'companyTypeId', label: 'Company Type', read: false, hidden: false },
      { field: 'employeeCodeAutogenerated', label: 'Employee Code Autogenerated', read: false, hidden: false },
      { field: 'code', label: 'Code', read: false, hidden: false },
      { field: 'serial', label: 'Serial', read: false, hidden: false },
      { field: 'address', label: 'Address', read: false, hidden: false },
      { field: 'countryId', label: 'Country', read: false, hidden: false },
      { field: 'domainName', label: 'Domain Name', read: false, hidden: false },
      { field: 'supportedLanguages', label: 'Supported Languages', read: false, hidden: false },
      { field: 'contactPerson', label: 'Contact Person', read: false, hidden: false },
      { field: 'defaultLanguageId', label: 'Default Language', read: false, hidden: false },
      { field: 'status', label: 'Status', read: false, hidden: false },
    ],
  },
  leadStatus: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'colorCode', label: 'Color Code(For Update Only)', read: false, hidden: false },
      { field: 'order', label: 'Order', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'colorCode', schemaKey: 'colorCode', label: 'Color Code', read: false, write: false, hidden: false },
      { field: 'order', schemaKey: 'order', label: 'Order', read: false, write: false, hidden: false },
    ],
  },
  contactRole: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  accountSource: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  businessTerritory: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  jobTitle: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  productInterest: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  subBusinessTerritory: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
      { field: 'businessTerritoryId', label: 'Business Territory', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'businessTerritoryId', schemaKey: 'businessTerritoryId', label: 'Business Territory', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  leadDepartment: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  leadCompanyType: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  lead: {
    listing: [
      { field: 'contactPerson', label: 'Contact Information', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
      { field: 'productId', label: 'Product Interest', read: false, hidden: false },
      { field: 'businessTerritoryId', label: 'Business Territory', read: false, hidden: false },
      { field: 'statusId', label: 'Status', read: false, hidden: false },
      { field: 'branchName', label: 'Branch Name', read: false, hidden: false },
    ],
    lead: [
      { field: 'leadSourceId', schemaKey: 'leadSourceId', label: 'Lead Source', read: false, hidden: false, write: false },
      { field: 'leadStatusId', schemaKey: 'leadStatusId', label: 'Lead Status', read: false, hidden: false, write: false },
    ],
    business: [
      { field: 'companyName', schemaKey: 'companyName', label: 'Company Name', read: false, hidden: false, write: false },
      { field: 'businessTerritoryId', schemaKey: 'businessTerritoryId', label: 'Business Territory', read: false, hidden: false, write: false },
      { field: 'businessSubUnitId', schemaKey: 'subBusinessTerritoryId', label: 'Business Sub Unit', read: false, hidden: false, write: false },
      { field: 'companyTypeId', schemaKey: 'companyTypeId', label: 'Company Type', read: false, hidden: false, write: false },
      { field: 'companySize', schemaKey: 'companySize', label: 'Company Size', read: false, hidden: false, write: false },
      { field: 'productInterest', schemaKey: 'productId', label: 'Product Interest', read: false, hidden: false, write: false },
      {
        field: 'contentInterestCategories',
        schemaKey: 'contentInterestCategory',
        label: 'Content Interest Categories',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'contentInterestTags', schemaKey: 'contentInterestTags', label: 'Content Interest Tags', read: false, hidden: false, write: false },
      {
        field: 'contentInterestLanguages',
        schemaKey: 'contentInterestLanguage',
        label: 'Content Interest Languages',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'csInstructions', schemaKey: 'comment', label: 'CS Instructions', read: false, hidden: false, write: false },
      { field: 'description', schemaKey: 'companyDescription', label: 'Description', read: false, hidden: false, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
      { field: 'platforms', schemaKey: 'platforms', label: 'Platforms', read: false, hidden: false, write: false },
    ],

    contact: [
      { field: 'salutation', schemaKey: 'title', label: 'Salutation', read: false, hidden: false, write: false },
      { field: 'firstName', schemaKey: 'firstName', label: 'First Name', read: false, hidden: false, write: false },
      { field: 'middleName', schemaKey: 'middleName', label: 'Middle Name', read: false, hidden: false, write: false },
      { field: 'lastName', schemaKey: 'lastName', label: 'last Name', read: false, hidden: false, write: false },
      { field: 'contactLanguage', schemaKey: 'spokenLanguageId', label: 'Contact Language', read: false, hidden: false, write: false },
      { field: 'primaryPhone', schemaKey: 'primaryPhone', label: 'Phone Number', read: false, hidden: false, write: false },
      { field: 'mobileNumber', schemaKey: 'mobileNumber', label: 'Mobile Number', read: false, hidden: false, write: false },
      { field: 'primaryEmail', schemaKey: 'primaryEmail', label: 'Email', read: false, hidden: false, write: false },
      { field: 'jobTitle', schemaKey: 'jobTitleId', label: 'Job Title', read: false, hidden: false, write: false },
      { field: 'departmentId', schemaKey: 'departmentId', label: 'Department', read: false, hidden: false, write: false },
      { field: 'contactRole', schemaKey: 'contactRoleId', label: 'Contact Role', read: false, hidden: false, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
      { field: 'socialMedia', schemaKey: 'socialMedia', label: 'Social Media', read: false, hidden: false, write: false },
      { field: 'diallingCode', schemaKey: 'diallingCode', label: 'Dialling Code', read: false, hidden: true, write: false },
    ],

    legal: [
      { field: 'companyLegalName', schemaKey: 'legalName', label: 'Company Legal Name', read: false, hidden: false, write: false },
      { field: 'registrationNumber', schemaKey: 'registrationNumber', label: 'Registration Number', read: false, hidden: false, write: false },
      { field: 'allContact', schemaKey: 'contactPersonId', label: 'All Contact', read: false, hidden: false, write: false },
      { field: 'postAddress', schemaKey: 'postAddress', label: 'Post Address', read: false, hidden: false, write: false },
      { field: 'legalAddress', schemaKey: 'legalAddress', label: 'Legal Address', read: false, hidden: false, write: false },
    ],

    financial: [
      { field: 'billTo', schemaKey: 'billToId', label: 'Bill To', read: false, hidden: false, write: false },
      { field: 'taxResidency', schemaKey: 'countryId', label: 'Tax Residency', read: false, hidden: false, write: false },
      { field: 'vatId', schemaKey: 'vatId', label: 'Vat Id', read: false, hidden: false, write: false },
      { field: 'withholdingTaxation', schemaKey: 'withholdingTaxation', label: 'with holding Taxation', read: false, hidden: false, write: false },
      { field: 'withholdingTaxRate', schemaKey: 'withholdingTaxRate', label: 'with holding TaxRate', read: false, hidden: false, write: false },
      { field: 'currency', schemaKey: 'currency', label: 'Currency', read: false, hidden: false, write: false },
      { field: 'accountingId', schemaKey: 'accountingId', label: 'Accounting Id', read: false, hidden: false, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
    ],
    system: [
      { field: 'cmsLink', schemaKey: 'cmsLink', label: 'Cms Link', read: false, hidden: false, write: false },
      { field: 'websiteId', schemaKey: 'websiteId', label: 'Website Id', read: false, hidden: false, write: false },
    ],
  },
  branchMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'companyTypeId', label: 'Company Type(For Update Only)', read: false, hidden: false },
      { field: 'address', label: 'Address(For Update Only)', read: false, hidden: false },
      { field: 'domainName', label: 'Domain Name', read: false, hidden: false },
      { field: 'supportedLanguages', label: 'Supported Languages(For Update Only)', read: false, hidden: false },
      { field: 'contactPerson', label: 'Contact Person', read: false, hidden: false },
      { field: 'defaultLanguageId', label: 'Default Language(For Update Only)', read: false, hidden: false },
      { field: 'status', label: 'Status', read: false, hidden: false },
      { field: 'vacationDetails', label: 'Vacation Details(For Update Only)', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'type', schemaKey: 'companyTypeId', label: 'Company Type', read: false, write: false, hidden: false },
      { field: 'defaultLanguage', schemaKey: 'defaultLanguageId', label: 'default Language', read: false, write: false, hidden: false },
      { field: 'supportedLanguage', schemaKey: 'supportedLanguages', label: 'Supported Language', read: false, write: false, hidden: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, write: false, hidden: false },
      { field: 'vacationDetails', schemaKey: 'vacationDetails', label: 'Vacation Details', read: false, write: false, hidden: false },
    ],
    details: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'groupName', label: 'Group Name', read: false, hidden: false },
      { field: 'companyTypeId', label: 'Company Type', read: false, hidden: false },
      { field: 'domainName', label: 'Domain Name', read: false, hidden: false },
      { field: 'supportedLanguages', label: 'Supported Languages', read: false, hidden: false },
      { field: 'defaultLanguageId', label: 'Default Language', read: false, hidden: false },
      { field: 'address', label: 'Address', read: false, hidden: false },
      { field: 'contactPerson', label: 'Contact Person', read: false, hidden: false },
    ],
  },
  department: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'type', label: 'Type', read: false, hidden: false },
      { field: 'headOfDepartmentId', label: 'Head Of Department', read: false, hidden: false },
      { field: 'seniorMemberIds', label: 'Senior Member(For Update Only)', read: false, hidden: false },
      { field: 'deputyHeadId', label: 'Deputy Head(For Update Only)', read: false, hidden: false },
      { field: 'parentId', label: 'Parent Department', read: false, hidden: false },
      { field: 'description', label: 'Description(For Update Only)', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'type', schemaKey: 'type', label: 'Type', read: false, write: false, hidden: false },
      { field: 'headOfDepartment', schemaKey: 'headOfDepartmentId', label: 'Head Of Department', read: false, write: false, hidden: false },
      { field: 'seniorMembers', schemaKey: 'seniorMemberIds', label: 'Senior Member', read: false, write: false, hidden: false },
      { field: 'deputyHead', schemaKey: 'deputyHeadId', label: 'Deputy Head', read: false, write: false, hidden: false },
      { field: 'parentDepartment', schemaKey: 'parentId', label: 'Parent Department', read: false, write: false, hidden: false },
    ],
  },
  role: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  paymentType: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  currencyMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  contractType: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  taxMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'type', label: 'Type', read: false, hidden: false },
      { field: 'rate', label: 'Rate', read: false, hidden: false },
      { field: 'order', label: 'Order', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'type', schemaKey: 'type', label: 'Type', read: false, write: false, hidden: false },
      { field: 'rate', schemaKey: 'rate', label: 'Rate', read: false, write: false, hidden: false },
      { field: 'order', schemaKey: 'order', label: 'Order', read: false, write: false, hidden: false },
    ],
  },
  healthInsurance: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'type', label: 'Type', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'type', schemaKey: 'type', label: 'Type', read: false, write: false, hidden: false },
    ],
  },
  financialYear: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'startDate', label: 'Start Date', read: false, hidden: false },
      { field: 'endDate', label: 'End Date', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'startDate', schemaKey: 'startDate', label: 'Start Date', read: false, hidden: false, write: false },
      { field: 'endDate', schemaKey: 'endDate', label: 'End Date', read: false, hidden: false, write: false },
    ],
  },
  positionMaster: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'departmentIds', label: 'Department', read: false, hidden: false },
      { field: 'description', label: 'Description(For Update Only)', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'jobDescription', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      { field: 'department', schemaKey: 'departmentId', label: 'Department', read: false, write: false, hidden: false },
    ],
  },
  costCenter: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'budgetAmount', label: 'Budget Amount', read: false, hidden: false },
      { field: 'financialYearId', label: 'Financial Year', read: false, hidden: false },
      { field: 'departmentId', label: 'Department', read: false, hidden: false },
      { field: 'budgetCurrency', label: 'Budget Currency(For Update Only)', read: false, hidden: false },
      { field: 'code', label: 'Code', read: false, hidden: false },
      { field: 'dailyLimit', label: 'Daily Limit', read: false, hidden: false },
      { field: 'weeklyLimit', label: 'Weekly Limit', read: false, hidden: false },
      { field: 'monthlyLimit', label: 'Monthly Limit', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'costCenterName', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'financialYear', schemaKey: 'financialYearId', label: 'Financial Year', read: false, write: false, hidden: false },
      { field: 'budgetAmount', schemaKey: 'budgetAmount', label: 'Budget Amount', read: false, write: false, hidden: false },
      { field: 'currency', schemaKey: 'budgetCurrency', label: 'Budget Currency', read: false, write: false, hidden: false },
      { field: 'department', schemaKey: 'departmentId', label: 'Department', read: false, hidden: false },
      { field: 'dailyLimit', schemaKey: 'dailyLimit', label: 'Daily Limit', read: false, write: false, hidden: false },
      { field: 'weeklyLimit', schemaKey: 'weeklyLimit', label: 'Weekly Limit', read: false, write: false, hidden: false },
      { field: 'monthlyLimit', schemaKey: 'monthlyLimit', label: 'Monthly Limit', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
    ],
  },
  userMaster: {
    listing: [
      { field: 'firstName', label: 'First Name', read: false, hidden: false },
      { field: 'lastName', label: 'Last Name', read: false, hidden: false },
      { field: 'email', label: 'Email', read: false, hidden: false },
      { field: 'roleId', label: 'Role', read: false, hidden: false },
      { field: 'departmentId', label: 'Department', read: false, hidden: false },
      { field: 'positionId', label: 'Position', read: false, hidden: false },
      { field: 'supervisorId', label: 'Supervisor', read: false, hidden: false },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
      { field: 'employeeCode', label: 'Employee Code', read: false, hidden: false },
    ],
    basicInfo: [
      { field: 'employeeCode', schemaKey: 'employeeCode', label: 'Employee Code', read: false, hidden: false },
      { field: 'firstName', schemaKey: 'firstName', label: 'First Name', read: false, hidden: false, write: false },
      { field: 'lastName', schemaKey: 'lastName', label: 'Last Name', read: false, hidden: false, write: false },
      { field: 'displayName', schemaKey: 'displayName', label: 'Display Name', read: false, hidden: false, write: false },
      { field: 'gender', schemaKey: 'gender', label: 'Gender', read: false, hidden: false, write: false },
      { field: 'workEmail', schemaKey: 'email', label: 'Email', read: false, hidden: false, write: false },
      { field: 'workPhoneNumber', schemaKey: 'phoneNumber', label: 'Phone Number', read: false, hidden: false, write: false },
      { field: 'diallingCode', schemaKey: 'diallingCode', label: 'Dialling Code', read: false, hidden: true, write: false },
      { field: 'officeLocation', schemaKey: 'officeLocation', label: 'Office Location', read: false, hidden: false, write: false },
      { field: 'departmentId', schemaKey: 'departmentId', label: 'Department', read: false, hidden: false, write: false },
      { field: 'positionId', schemaKey: 'positionId', label: 'Position', read: false, hidden: false, write: false },
      { field: 'roleId', schemaKey: 'roleId', label: 'Role', read: false, hidden: false, write: false },
      { field: 'supervisorId', schemaKey: 'supervisorId', label: 'Supervisor', read: false, hidden: false, write: false },
      { field: 'spokenLanguageId', schemaKey: 'spokenLanguageIds', label: 'Spoken Language', read: false, hidden: false, write: false },
      { field: 'profileSelection', schemaKey: 'profileSelection', label: 'Profile Selection', read: false, hidden: false, write: false },
    ],
    userPersonalProfile: [
      { field: 'email', schemaKey: 'email', label: 'Email', read: false, hidden: false, write: false },
      { field: 'phoneNumber', schemaKey: 'phoneNumber', label: 'Phone Number', read: false, hidden: false, write: false },
      { field: 'diallingCode', schemaKey: 'diallingCode', label: 'Dialling Code', read: false, hidden: true, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
      { field: 'birthAddress', schemaKey: 'birthAddress', label: 'Birth Address', read: false, hidden: false, write: false },
      { field: 'nationality', schemaKey: 'nationalityId', label: 'Nationality', read: false, hidden: false, write: false },
      { field: 'religion', schemaKey: 'religion', label: 'Religion', read: false, hidden: false, write: false },
      { field: 'dateOfBirth', schemaKey: 'dateOfBirth', label: 'Date Of Birth', read: false, hidden: false, write: false },
      { field: 'addressProof', schemaKey: 'addressProof', label: 'Address Proof', read: false, hidden: false, write: false },
    ],
    userEducation: [
      { field: 'educationType', schemaKey: 'educationType', label: 'Education Type', read: false, hidden: false, write: false },
      {
        field: 'educationQualification',
        schemaKey: 'educationQualification',
        label: 'Education Qualification',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'institutionName', schemaKey: 'institutionName', label: 'Institution Name', read: false, hidden: false, write: false },
      { field: 'completionDate', schemaKey: 'completionDate', label: 'Completion Date', read: false, hidden: false, write: false },
      { field: 'countryOfInsurance', schemaKey: 'countryOfInsurance', label: 'Country Of Insurance', read: false, hidden: false, write: false },
      { field: 'otherDetails', schemaKey: 'otherDetails', label: 'Other Details', read: false, hidden: false, write: false },
    ],
    userContractInformation: [
      { field: 'contractStatus', schemaKey: 'status', label: 'Status', read: false, hidden: false, write: false },
      { field: 'employmentTypeId', schemaKey: 'employmentTypeId', label: 'Employment Type', read: false, hidden: false, write: false },
      { field: 'contractCreationDate', schemaKey: 'contractCreationDate', label: 'Contract Creation Date', read: false, hidden: false, write: false },
      { field: 'hiringDate', schemaKey: 'hiringDate', label: 'Hiring Date', read: false, hidden: false, write: false },
      {
        field: 'revisedDateOfJoining',
        schemaKey: 'revisedDateOfJoining',
        label: 'Revised Date Of Joining Date',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'contractDuration', schemaKey: 'contractDuration', label: 'Contract Duration', read: false, hidden: false, write: false },
      { field: 'contractEndDate', schemaKey: 'contractEndDate', label: 'Contract End Date', read: false, hidden: false, write: false },
      { field: 'probationPeriod', schemaKey: 'probationPeriod', label: 'Probation Period', read: false, hidden: false, write: false },
      { field: 'noticePeriod', schemaKey: 'noticePeriod', label: 'Notice Period', read: false, hidden: false, write: false },
      { field: 'weeklyHours', schemaKey: 'weeklyHours', label: 'Weekly Hours', read: false, hidden: false, write: false },
      { field: 'vacationDays', schemaKey: 'vacationDays', label: 'Vacation Days', read: false, hidden: false, write: false },
      { field: 'passportNumber', schemaKey: 'passportNumber', label: 'passport Number', read: false, hidden: false, write: false },
      { field: 'passportValidity', schemaKey: 'passportValidity', label: 'Passport Validity', read: false, hidden: false, write: false },
      {
        field: 'residencePermitValidity',
        schemaKey: 'residencePermitValidity',
        label: 'residence Permit Validity',
        read: false,
        hidden: false,
        write: false,
      },
      {
        field: 'visaTypeAdditionalComment',
        schemaKey: 'visaTypeAdditionalComment',
        label: 'Visa Type Additional Comment',
        read: false,
        hidden: false,
        write: false,
      },
      {
        field: 'dateOfSignature',
        schemaKey: 'dateOfSignature',
        label: 'Date Of Signature',
        read: false,
        hidden: false,
        write: false,
      },
    ],
    userPayrollInformation: [
      { field: 'costCenterId', schemaKey: 'costCenterId', label: 'Cost Center', read: false, hidden: false, write: false },
      { field: 'socialSecurityNumber', schemaKey: 'socialSecurityNumber', label: 'Social Security Number', read: false, hidden: false, write: false },
      {
        field: 'taxIdentificationNumber',
        schemaKey: 'taxIdentificationNumber',
        label: 'Tax Identification Number',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'taxClass', schemaKey: 'taxClass', label: 'Tax Class', read: false, hidden: false, write: false },
      { field: 'healthInsuranceType', schemaKey: 'healthInsuranceType', label: 'Health Insurance Type', read: false, hidden: false, write: false },
      { field: 'maritalStatus', schemaKey: 'maritalStatus', label: 'Marital Status', read: false, hidden: false, write: false },
      { field: 'childAllowance', schemaKey: 'childAllowance', label: 'Child Allowance', read: false, hidden: false, write: false },
      { field: 'taxAllowance', schemaKey: 'taxAllowance', label: 'Tax Allowance', read: false, hidden: false, write: false },
      { field: 'religiousDenomination', schemaKey: 'religiousDenomination', label: 'Religious Denomination', read: false, hidden: false, write: false },
      { field: 'currency', schemaKey: 'currency', label: 'Currency', read: false, hidden: false, write: false },
      { field: 'jobType', schemaKey: 'jobType', label: 'Job Type', read: false, hidden: false, write: false },
      { field: 'companyPensionPlan', schemaKey: 'companyPensionPlan', label: 'Company Pension Plan', read: false, hidden: false, write: false },
    ],
    userTemplateInformation: [
      { field: 'userProfileId', schemaKey: 'userProfileId', label: 'User Template', read: false, hidden: false, write: false },
      { field: 'childSick', schemaKey: 'childSick', label: 'Child Sick', read: false, hidden: false, write: false },
      { field: 'childbearing', schemaKey: 'childbearing', label: 'Child Bearing', read: false, hidden: false, write: false },
      { field: 'compassionateLeave', schemaKey: 'compassionateLeave', label: 'Compassionate Leave', read: false, hidden: false, write: false },
      { field: 'compensation', schemaKey: 'compensation', label: 'Compensation', read: false, hidden: false, write: false },
      { field: 'maternalLeave', schemaKey: 'maternalLeave', label: 'Maternal Leave', read: false, hidden: false, write: false },
      {
        field: 'operationalRelocation',
        schemaKey: 'operationalRelocation',
        label: 'Operational Relocation',
        read: false,
        hidden: false,
        write: false,
      },
      {
        field: 'additionalVacation',
        schemaKey: 'additionalVacation',
        label: 'Additional Vacation',
        read: false,
        hidden: false,
        write: false,
      },
      { field: 'paternal', schemaKey: 'paternal', label: 'Paternal Leave', read: false, hidden: false, write: false },
      { field: 'sickLeave', schemaKey: 'sickLeave', label: 'Sick Leave', read: false, hidden: false, write: false },
      { field: 'specialLeave', schemaKey: 'specialLeave', label: 'Special Leave', read: false, hidden: false, write: false },
      { field: 'unpaidLeave', schemaKey: 'unpaidLeave', label: 'Unpaid Leave', read: false, hidden: false, write: false },
      { field: 'vacation', schemaKey: 'vacation', label: 'Vacation Leave', read: false, hidden: false, write: false },
      { field: 'weddingLeave', schemaKey: 'weddingLeave', label: 'Wedding Leave', read: false, hidden: false, write: false },
    ],
    userBankDetail: [
      { field: 'name', schemaKey: 'holderName', label: 'Holder Name', read: false, hidden: false, write: false },
      { field: 'bankName', schemaKey: 'bankName', label: 'Bank Name', read: false, hidden: false, write: false },
      { field: 'bic', schemaKey: 'bic', label: 'Bic', read: false, hidden: false, write: false },
      { field: 'iban', schemaKey: 'iban', label: 'Iban', read: false, hidden: false, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
      { field: 'swiftCode', schemaKey: 'swiftCode', label: 'Swift Code', read: false, hidden: false, write: false },
    ],
    userEmergencyContactDetail: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, hidden: false, write: false },
      { field: 'relationship', schemaKey: 'relationshipWithEmployee', label: 'Relationship With Employee', read: false, hidden: false, write: false },
      { field: 'diallingCode', schemaKey: 'diallingCode', label: 'Dialling Code', read: false, hidden: true, write: false },
      { field: 'phoneNumber', schemaKey: 'phoneNumber', label: 'Phone Number', read: false, hidden: false, write: false },
      { field: 'address', schemaKey: 'address', label: 'Address', read: false, hidden: false, write: false },
    ],
    userChildren: [
      { field: 'firstName', schemaKey: 'firstName', label: 'First Name', read: false, hidden: false, write: false },
      { field: 'lastName', schemaKey: 'lastName', label: 'Last Name', read: false, hidden: false, write: false },
      { field: 'birthDate', schemaKey: 'dateOfBirth', label: 'Date Of Birth', read: false, hidden: false, write: false },
      { field: 'relation', schemaKey: 'relation', label: 'Relation', read: false, hidden: false, write: false },
    ],
    userTempResource: [
      { field: 'resourceType', label: 'Resource Type', read: false, hidden: false },
      { field: 'documentType', label: 'Document Type', read: false, hidden: false },
      { field: 'resourceDetail', label: 'Resource Detail', read: false, hidden: false },
      { field: 'status', label: 'Status', read: false, hidden: false },
    ],
    details: [
      { field: 'firstName', label: 'First Name', read: false, hidden: false },
      { field: 'lastName', label: 'Last Name', read: false, hidden: false },
      { field: 'departmentId', label: 'Department', read: false, hidden: false },
      { field: 'supervisorId', label: 'Supervisor', read: false, hidden: false },
      { field: 'userProfile', label: 'User Profile', read: false, hidden: false },
      { field: 'userLeaveInformation', label: 'User Leave Information', read: false, hidden: false },
      { field: 'countOfTotalLeaves', label: 'Count Of Total Leaves', read: false, hidden: false },
      { field: 'deductCountOfLeaves', label: 'Deduct Count Of Leaves', read: false, hidden: false },
      { field: 'remainingLeaveInformation', label: 'Remaining Leave Information', read: false, hidden: false },
      { field: 'positionId', label: 'Position', read: false, hidden: false },
    ],
    userHealthAreaInformation: [
      { field: 'healthNotes', schemaKey: 'healthNotes', label: 'Health Notes', read: false, hidden: false, write: false },
      { field: 'bloodGroup', schemaKey: 'bloodGroup', label: 'Blood Group', read: false, hidden: false, write: false },
      { field: 'unemploymentInsurance', schemaKey: 'unemploymentInsurance', label: 'Unemployment Insurance', read: false, hidden: false, write: false },
    ],
  },
  userProfile: {
    listing: [
      { field: 'name', label: 'Name', read: false, hidden: false },
      { field: 'description', label: 'Description', read: false, hidden: false },
      {
        field: 'multipleEmploymentDate',
        label: 'Multiple Employment Date(for Update Only)',
        read: false,
        hidden: false,
      },
      {
        field: 'profileActivation',
        label: 'Profile Activation(for Update Only)',
        read: false,
        hidden: false,
      },
      {
        field: 'vacationDetails',
        label: 'Vacation Details(for Update Only)',
        read: false,
        hidden: false,
      },
      { field: 'companyName', label: 'Company Name', read: false, hidden: false },
    ],
    update: [
      { field: 'name', schemaKey: 'name', label: 'Name', read: false, write: false, hidden: false },
      { field: 'description', schemaKey: 'description', label: 'Description', read: false, write: false, hidden: false },
      {
        field: 'multipleEmploymentDate',
        schemaKey: 'multipleEmploymentDate',
        label: 'Multiple Employment Date',
        read: false,
        write: false,
        hidden: false,
      },
      { field: 'profileActivation', schemaKey: 'profileActivation', label: 'Profile Activation', read: false, write: false, hidden: false },
      { field: 'vacationDetails', schemaKey: 'vacationDetails', label: 'Vacation Details', read: false, write: false, hidden: false },
    ],
  },
  worker: {
    listing: [
      {
        field: 'thumbnail',
        label: 'Photo',
        read: false,
        hidden: false,
      },
      {
        field: 'name',
        label: 'Name',
        read: false,
        hidden: false,
      },
      {
        field: 'rating',
        label: 'Rating',
        read: false,
        hidden: false,
      },
      {
        field: 'pastJobs',
        label: 'Past Jobs',
        read: false,
        hidden: false,
      },
      {
        field: 'address',
        label: 'Address',
        read: false,
        hidden: false,
      },
    ],
    update: [
      {
        field: 'thumbnail',
        label: 'Photo',
        schemaKey: 'thumbnail',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'name',
        label: 'Name',
        schemaKey: 'name',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        schemaKey: 'location',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'workerType',
        label: 'Worker Type',
        schemaKey: 'workerType',
        read: false,
        write: false,
        hidde: false,
      },
      {
        field: 'stateId',
        label: 'State',
        schemaKey: 'stateId',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'countryId',
        label: 'Country',
        schemaKey: 'countryId',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'cityId',
        label: 'City',
        schemaKey: 'cityId',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'lat',
        label: 'Lat',
        schemaKey: 'lat',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'lang',
        label: 'Lang',
        schemaKey: 'lang',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'address',
        label: 'Address',
        schemaKey: 'address',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'workingRadius',
        label: 'Working Radius',
        schemaKey: 'workingRadius',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'meansOfTravel',
        label: 'Means Of Travel',
        schemaKey: 'meansOfTravel',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'skills',
        label: 'Skills',
        schemaKey: 'skills',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'equipment',
        label: 'Equipment',
        schemaKey: 'equipment',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'locationTracking',
        label: 'Location Tracking',
        schemaKey: 'locationTracking',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'nativeLanguageIds',
        label: 'Native Languages',
        schemaKey: 'nativeLanguageIds',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'fluentLanguageIds',
        label: 'Fluent Languages',
        schemaKey: 'fluentLanguageIds',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'semiFluentLanguageIds',
        label: 'Semi-Fluent Languages',
        schemaKey: 'semiFluentLanguageIds',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'averageRating',
        label: 'Average Rating',
        schemaKey: 'averageRating',
        write: false,
        read: false,
        hidden: true,
      },
      {
        field: 'isTermsAndConditionsAccepted',
        label: 'Terms and Conditions',
        schemaKey: 'isTermsAndConditionsAccepted',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'isPrivacyPolicyAccepted',
        label: 'Privacy Policy',
        schemaKey: 'isPrivacyPolicyAccepted',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'isActive',
        label: 'Active Employee',
        schemaKey: 'isActive',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'isBlacklist',
        label: 'Blacklist Employee',
        schemaKey: 'isBlacklist',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'notes',
        label: 'Notes',
        schemaKey: 'notes',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'email',
        label: 'Email',
        schemaKey: 'email',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'phoneNumber',
        label: 'Phone Number',
        schemaKey: 'phoneNumber',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'linkedIn',
        label: 'Linked In',
        schemaKey: 'linkedIn',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'twitter',
        label: 'Twitter',
        schemaKey: 'twitter',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'blog',
        label: 'Blog',
        schemaKey: 'blog',
        read: false,
        write: false,
        hidden: false,
      },
    ],
    listingForBooking: [
      {
        field: 'name',
        label: 'Name',
        read: false,
        hidden: false,
      },
      {
        field: 'workerType',
        label: 'Worker Type',
        read: false,
        hidden: false,
      },
      {
        field: 'lat',
        label: 'Lat',
        read: false,
        hidden: true,
      },
      {
        field: 'lang',
        label: 'Lang',
        read: false,
        hidden: true,
      },
      {
        field: 'rating',
        label: 'Rating',
        read: false,
        hidden: false,
      },
      {
        field: 'pastJobs',
        label: 'Past Jobs',
        read: false,
        hidden: false,
      },
      {
        field: 'distance',
        label: 'Distance',
        read: false,
        hidden: false,
      },
      {
        field: 'status',
        label: 'Status',
        read: false,
        hidden: false,
      },
    ],
    financeUpdate: [
      {
        field: 'travelExpenses',
        label: 'Travel Expenses',
        schemaKey: 'travelExpenses',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'monthlyFixedPrice',
        label: 'Monthly Fixed Price',
        schemaKey: 'monthlyFixedPrice',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'fixedPricePer',
        label: 'Fixed Price Per',
        schemaKey: 'fixedPricePer',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'perExtraStoryPrice',
        label: 'Price Per Extra Story',
        schemaKey: 'perExtraStoryPrice',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'storiesPerMonth',
        label: 'Stories per rate period',
        schemaKey: 'storiesPerMonth',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'payPal',
        label: 'PayPal',
        schemaKey: 'payPal',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'bankName',
        label: 'Bank Name',
        schemaKey: 'bankName',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'holderName',
        label: 'Account Holder Name',
        schemaKey: 'holderName',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'bic',
        label: 'BIC',
        schemaKey: 'bic',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'iban',
        label: 'IBAN',
        schemaKey: 'iban',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        schemaKey: 'location',
        read: false,
        write: false,
        hidden: false,
      },
    ],
    financialTransactionListing: [
      {
        field: 'payment',
        label: 'Payment',
        read: false,
        hidden: false,
      },
      {
        field: 'invoiceDate',
        label: 'Invoice Date',
        read: false,
        hidden: false,
      },
      {
        field: 'status',
        label: 'Status',
        read: false,
        hidden: false,
      },
      {
        field: 'storyId',
        label: 'Story',
        read: false,
        hidden: false,
      },
      {
        field: 'workerId',
        label: 'Worker',
        read: false,
        hidden: false,
      },
      {
        field: 'financialYearId',
        label: 'Financial Year',
        read: false,
        hidden: false,
      },
    ],
    historyListing: [
      {
        field: 'title',
        label: 'Title',
        read: false,
        hidden: false,
      },
      {
        field: 'averageRating',
        label: 'Average Rating',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: false,
      },
      {
        field: 'startDate',
        label: 'Start Date',
        read: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        read: false,
        hidden: false,
      },
      {
        field: 'coverageId',
        label: 'Coverage',
        read: false,
        hidden: false,
      },
      {
        field: 'storyId',
        label: 'Story',
        read: false,
        hidden: false,
      },
      {
        field: 'workerId',
        label: 'Worker',
        read: false,
        hidden: false,
      },
    ],
  },
  coverage: {
    listing: [
      {
        field: 'startDate',
        label: 'Start Date',
        read: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        read: false,
        hidden: false,
      },
      {
        field: 'title',
        label: 'Title',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: false,
      },
      {
        field: 'storyCount',
        label: 'Story Count',
        read: false,
        hidden: false,
      },
      {
        field: 'budget',
        label: 'Budget',
        read: false,
        hidden: false,
      },
    ],
    update: [
      {
        field: 'startDate',
        label: 'Start Date',
        schemaKey: 'startDate',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        schemaKey: 'endDate',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'title',
        label: 'Title',
        schemaKey: 'title',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        schemaKey: 'description',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        schemaKey: 'location',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'care',
        label: 'Why We Care',
        schemaKey: 'care',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'note',
        label: 'Notes & Restrictions',
        schemaKey: 'note',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'keywords',
        label: 'Keywords',
        schemaKey: 'keywords',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'links',
        label: 'Links',
        schemaKey: 'links',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'budget',
        label: 'Budget',
        schemaKey: 'budget',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'breakingNews',
        label: 'Breaking News',
        schemaKey: 'breakingNews',
        read: false,
        write: false,
        hidden: false,
      },
    ],
    details: [
      {
        field: 'startDate',
        label: 'Start Date',
        read: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        read: false,
        hidden: false,
      },
      {
        field: 'title',
        label: 'Title',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        read: false,
        hidden: false,
      },
      {
        field: 'care',
        label: 'Why We Care',
        read: false,
        hidden: false,
      },
      {
        field: 'note',
        label: 'Notes & Restrictions',
        read: false,
        hidden: false,
      },
      {
        field: 'keywords',
        label: 'Keywords',
        read: false,
        hidden: false,
      },
      {
        field: 'links',
        label: 'Links',
        read: false,
        hidden: false,
      },
      {
        field: 'budget',
        label: 'Budget',
        read: false,
        hidden: false,
      },
      {
        field: 'story',
        label: 'Story',
        read: false,
        hidden: false,
      },
    ],
  },
  story: {
    update: [
      {
        field: 'startDate',
        label: 'Start Date',
        schemaKey: 'startDate',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        schemaKey: 'endDate',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'title',
        label: 'Title',
        schemaKey: 'title',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        schemaKey: 'description',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        schemaKey: 'location',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'care',
        label: 'Why We Care',
        schemaKey: 'care',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'note',
        label: 'Notes & Restrictions',
        schemaKey: 'note',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'keywords',
        label: 'Keywords',
        schemaKey: 'keywords',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'links',
        label: 'Links',
        schemaKey: 'links',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'budget',
        label: 'Budget',
        schemaKey: 'budget',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'newsType',
        label: 'News Type',
        schemaKey: 'newsType',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'storyType',
        label: 'Story Type',
        schemaKey: 'storyType',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'rights',
        label: 'Rights',
        schemaKey: 'rights',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'equipment',
        label: 'Equipment',
        schemaKey: 'equipment',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'needsVerification',
        label: 'Needs Verification',
        schemaKey: 'needsVerification',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'automaticTranslation',
        label: 'Automatic Translation',
        schemaKey: 'automaticTranslation',
        read: false,
        write: false,
        hidden: false,
      },
    ],
    detail: [
      {
        field: 'startDate',
        label: 'Start Date',
        read: false,
        hidden: false,
      },
      {
        field: 'endDate',
        label: 'End Date',
        read: false,
        hidden: false,
      },
      {
        field: 'title',
        label: 'Title',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: false,
      },
      {
        field: 'location',
        label: 'Location',
        read: false,
        hidden: false,
      },
      {
        field: 'care',
        label: 'Why We Care',
        read: false,
        hidden: false,
      },
      {
        field: 'note',
        label: 'Notes & Restrictions',
        read: false,
        hidden: false,
      },
      {
        field: 'keywords',
        label: 'Keywords',
        read: false,
        hidden: false,
      },
      {
        field: 'links',
        label: 'Links',
        read: false,
        hidden: false,
      },
      {
        field: 'budget',
        label: 'Budget',
        read: false,
        hidden: false,
      },
      {
        field: 'newsType',
        label: 'News Type',
        read: false,
        hidden: false,
      },
      {
        field: 'storyType',
        label: 'Story Type',
        read: false,
        hidden: false,
      },
      {
        field: 'rights',
        label: 'Rights',
        read: false,
        hidden: false,
      },
      {
        field: 'equipment',
        label: 'Equipment',
        read: false,
        hidden: false,
      },
      {
        field: 'needsVerification',
        label: 'Needs Verification',
        read: false,
        hidden: false,
      },
      {
        field: 'automaticTranslation',
        label: 'Automatic Translation',
        read: false,
        hidden: false,
      },
    ],
    proposalDetail: [
      {
        field: 'location',
        label: 'Location',
        read: false,
        hidden: false,
      },
      {
        field: 'duration',
        label: 'Duration',
        read: false,
        hidden: false,
      },
      {
        field: 'equipment',
        label: 'Equipment',
        read: false,
        hidden: false,
      },
      {
        field: 'verificationNeeded',
        label: 'Verification Needed',
        read: false,
        hidden: false,
      },
      {
        field: 'exclusivity',
        label: 'Exclusivity',
        read: false,
        hidden: false,
      },
      {
        field: 'paymentRate',
        label: 'Payment Rate',
        read: false,
        hidden: false,
      },
      {
        field: 'travelExpenses',
        label: 'Travel Expenses',
        read: false,
        hidden: false,
      },
      {
        field: 'cancellationFee',
        label: 'Cancellation Fee',
        read: false,
        hidden: false,
      },
      {
        field: 'instruction',
        label: 'Instruction',
        read: false,
        hidden: false,
      },
    ],
  },
  storyBooking: {
    workerBookingDetail: [
      {
        field: 'name',
        label: 'Name',
        read: false,
        hidden: false,
      },
      {
        field: 'email',
        label: 'Email',
        read: false,
        hidden: false,
      },
      {
        field: 'phoneNumber',
        label: 'Phone Number',
        read: false,
        hidden: false,
      },
      {
        field: 'pastJobs',
        label: 'Past Jobs',
        read: false,
        hidden: false,
      },
      {
        field: 'totalPastJobs',
        label: 'Total Past Jobs',
        read: false,
        hidden: false,
      },
      {
        field: 'averageRating',
        label: 'Average Rating',
        read: false,
        hidden: false,
      },
      {
        field: 'distance',
        label: 'Distance',
        read: false,
        hidden: false,
      },
      {
        field: 'equipment',
        label: 'Equipment',
        read: false,
        hidden: false,
      },
      {
        field: 'twitter',
        label: 'Twitter',
        read: false,
        hidden: false,
      },
    ],
  },
  templateMaster: {
    listing: [
      {
        field: 'name',
        label: 'Name',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: true,
      },
      {
        field: 'html',
        label: 'Html',
        read: false,
        hidden: true,
      },
      {
        field: 'status',
        label: 'Status',
        read: false,
        hidden: true,
      },
      {
        field: 'docType',
        label: 'Doc Type',
        read: false,
        hidden: true,
      },
      {
        field: 'type',
        label: 'Type',
        read: false,
        hidden: true,
      },
      {
        field: 'userType',
        label: 'User Type',
        read: false,
        hidden: true,
      },
    ],
    update: [
      {
        field: 'name',
        label: 'Name',
        schemaKey: 'name',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        schemaKey: 'description',
        write: false,
        read: false,
        hidden: true,
      },
      {
        field: 'html',
        label: 'Html',
        schemaKey: 'html',
        read: false,
        write: false,
        hidden: false,
      },
      {
        field: 'status',
        label: 'Status',
        schemaKey: 'status',
        read: false,
        write: false,
        hidden: true,
      },
      {
        field: 'type',
        label: 'Type',
        schemaKey: 'type',
        read: false,
        hidden: false,
        write: false,
      },
      {
        field: 'userType',
        label: 'User Type',
        schemaKey: 'userType',
        read: false,
        hidden: false,
        write: false,
      },
    ],
  },
  userContract: {
    listing: [
      {
        field: 'userContracts.name',
        label: 'Name',
        read: false,
        hidden: true,
      },
      {
        field: 'userName',
        label: 'User Name',
        read: false,
        hidden: false,
      },
      {
        field: 'templateName',
        label: 'Template Name',
        read: false,
        hidden: false,
      },
      {
        field: 'description',
        label: 'Description',
        read: false,
        hidden: true,
      },
      {
        field: 'html',
        label: 'Html',
        read: false,
        hidden: true,
      },
      {
        field: 'status',
        label: 'Status',
        read: false,
        hidden: true,
      },
      {
        field: 'docType',
        label: 'Doc Type',
        read: false,
        hidden: true,
      },
      {
        field: 'contractDetail',
        label: 'Contract Detail',
        read: false,
        hidden: false,
      },
      {
        field: 'isPdfGenerated',
        label: 'isPdfGenerated',
        read: false,
        hidden: true,
      },
      {
        field: 'userDetail',
        label: 'user Detail',
        read: false,
        hidden: true,
      },
    ],
  },
};

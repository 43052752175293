import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { AppComponent } from '../../../app.component';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { RecordsListComponent } from 'app/core/base-classes/records-list-component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ViewDetailsComponent } from '../notifications/view-details/view-details.component';
import { Router } from '@angular/router';
import { SupabaseService } from '../../../core/auth/supabase.service';
import { AbsenceTypeService } from '../../../modules/admin/hr-masters/absence-type/absence-type.service';
import { STATICS } from '../../../core/config/static/static';
import { UpdateEventComponent } from '../../../modules/admin/hr-masters/calendar/update-event/update-event.component';
import { CalendarService } from '../../../../app/modules/admin/hr-masters/calendar/calendar.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
})
export class NotificationsComponent extends RecordsListComponent<any> {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  notification = [];
  currentUser: any;
  isManagerHR = false;
  absenceTypes: any;
  count: any;
  constructor(
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private matDialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    private _supabaseService: SupabaseService,
    private _absenceTypeService: AbsenceTypeService,
    private _calenderService: CalendarService,

    @Inject(AppComponent) private appComponent: AppComponent
  ) {
    super(appComponent);
    this.showLoader();
    this._supabaseService.getCurrentUser$.pipe().subscribe((user: any) => {
      this.currentUser = user;
      if (this._supabaseService.hasPermissions(this.currentUser.permission, STATICS.MODULES.ABSENCE, STATICS.APIS.ABSENCE_TYPE.FILTER)) {
        if (this.currentUser && this._supabaseService.session) {
          this._absenceTypeService.filterList().subscribe(
            (data) => {
              this.absenceTypes = data;
            },
            (err) => {
              this.hideLoader();
              this.showErrorToast(`Error in fetching record ${err.error.message}`);
            }
          );
        }
      }
      if (this._supabaseService.hasPermissions(this.currentUser.permission, 'Notification', '/notifications/getNotification')) {
        if (this.currentUser && this._supabaseService.session) {
          this.getRecordsList();
        }
      }
      if (this._supabaseService.hasPermissions(this.currentUser.permission, 'HR', 'HR')) {
        this.isManagerHR = true;
      }
      if (this._supabaseService.hasPermissions(this.currentUser.permission, 'Manager', 'Manager')) {
        this.isManagerHR = true;
      }
    });
  }

  getRecordsList() {
    this.showLoader();
    this._notificationsService.getAll({ isRead: false }).subscribe(
      (data: any) => {
        this._changeDetectorRef.markForCheck();
        this.hideLoader();
        this.notification = data?.result;
        this.count = data?.count;
        if (this.notification.length > 0) {
          for (let index = 0; index < this.notification.length; index++) {
            let message = '';
            let requestType = '';
            let depName = '';

            // Handle deleted absence requests
            if (this.notification[index].isDeleted) {
              depName = this.notification[index]?.actionBy?.departmentName ?? '';
              message = `Absence request deleted by ${this.notification[index].actionBy.lastName} ${this.notification[index].actionBy.firstName} (${depName})`;
            }
            // Handle other absence request statuses
            else if (this.notification[index].absenceStatus === 1) {
              if (this.notification[index].userDetail._id !== this.currentUser.userId) {
                if (this.notification[index].userDetail._id !== this.notification[index].requestedBy._id) {
                  depName = this.notification[index]?.requestedBy?.departmentName ?? '';
                  requestType = this.notification[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
                  message =
                    requestType +
                    this.notification[index].requestedBy.lastName +
                    ' ' +
                    this.notification[index].requestedBy.firstName +
                    ' (' +
                    depName +
                    ') for ' +
                    this.notification[index].userDetail.lastName +
                    ' ' +
                    this.notification[index].userDetail.firstName +
                    ' (' +
                    (this.notification[index]?.userDetail?.departmentName || '') +
                    ')';
                } else {
                  depName = this.notification[index]?.requestedBy?.departmentName ?? '';
                  requestType = this.notification[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
                  message =
                    requestType + this.notification[index].requestedBy.lastName + ' ' + this.notification[index].requestedBy.firstName + ' (' + depName + ')';
                }
              } else {
                depName = this.notification[index]?.requestedBy?.departmentName ?? '';
                requestType = this.notification[index]?.isUpdated ? 'Absence request updated by ' : 'Absence request created by ';
                message =
                  requestType +
                  this.notification[index].requestedBy.lastName +
                  ' ' +
                  this.notification[index]?.requestedBy?.firstName +
                  ' (' +
                  depName +
                  ') for you';
              }
            } else if (this.notification[index].absenceStatus === 4 || this.notification[index].absenceStatus === 6) {
              if (this.notification[index].actionTakenForUser._id !== this.currentUser.userId) {
                depName = this.notification[index]?.actionBy?.departmentName ?? '';
                message =
                  'Absence request rejected by ' +
                  this.notification[index].actionBy.lastName +
                  ' ' +
                  this.notification[index].actionBy.firstName +
                  ' (' +
                  depName +
                  ') of ' +
                  this.notification[index].actionTakenForUser.lastName +
                  ' ' +
                  this.notification[index].actionTakenForUser.firstName;
              } else {
                depName = this.notification[index]?.actionBy?.departmentName ?? '';
                message =
                  'Your Absence request rejected by ' +
                  this.notification[index].actionBy.lastName +
                  ' ' +
                  this.notification[index].actionBy.firstName +
                  ' (' +
                  depName +
                  ')';
              }
            } else if (this.notification[index].absenceStatus === 5 || this.notification[index].absenceStatus === 7) {
              if (this.notification[index].actionBy && this.notification[index].actionTakenForUser._id !== this.currentUser.userId) {
                if (this.notification[index].actionBy) {
                  depName = this.notification[index]?.actionBy?.departmentName ?? '';
                  message =
                    'Absence request approved by ' +
                    this.notification[index].actionBy.lastName +
                    ' ' +
                    this.notification[index].actionBy.firstName +
                    ' (' +
                    depName +
                    ') of ' +
                    this.notification[index].actionTakenForUser.lastName +
                    ' ' +
                    this.notification[index].actionTakenForUser.firstName;
                } else {
                  depName = this.notification[index]?.requestedBy?.departmentName ?? '';
                  message =
                    'Absence request approved by ' +
                    this.notification[index].requestedBy.lastName +
                    ' ' +
                    this.notification[index].requestedBy.firstName +
                    ' (' +
                    depName +
                    ') of ' +
                    this.notification[index].userDetail.lastName +
                    ' ' +
                    this.notification[index].userDetail.firstName;
                }
              } else {
                if (this.notification[index].actionBy) {
                  depName = this.notification[index]?.actionBy?.departmentName ?? '';
                  message =
                    'Your Absence request approved by ' +
                    this.notification[index].actionBy.lastName +
                    ' ' +
                    this.notification[index].actionBy.firstName +
                    ' (' +
                    depName +
                    ')';
                } else {
                  depName = this.notification[index]?.requestedBy?.departmentName ?? '';
                  message =
                    'Your Absence request approved by ' +
                    this.notification[index].requestedBy.lastName +
                    ' ' +
                    this.notification[index].requestedBy.firstName +
                    ' (' +
                    depName +
                    ')';
                }
              }
            }

            this.notification[index]['message'] = message;
          }
        }
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  callBothFunctions() {
    this.closePanel();
    this.openDetailsDialog();
  }

  openDetailsDialog(): void {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '48%',
      panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
      data: {
        isManagerHR: this.isManagerHR,
        currentUser: this.currentUser,
        absenceTypes: this.absenceTypes,
      },
    };

    const dialogRef: MatDialogRef<ViewDetailsComponent> = this.matDialog.open(ViewDetailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== undefined && result !== null && result.status) {
        // Handle the result if needed
      }
    });
  }

  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  closePanel(): void {
    this._overlayRef.detach();
  }

  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  getBackgroundStyle(index: number): { [key: string]: string } {
    const colors = ['#D6EBBC', '#EBF5DD', '#F4F4F4'];
    const color = colors[index % colors.length];
    return { 'background-color': color };
  }

  navigate(notification) {
    this.showLoader();

    if (!notification.isRead) {
      this._notificationsService.markRead(notification._id).subscribe(
        () => {
          //
        },
        (err) => {
          this.showErrorToast(`Error in fetching records ${err.error.message}`);
          this.hideLoader();
        }
      );
    }
    notification.userDetail['userId'] = notification.userDetail?._id;
    this._calenderService.getRequestDetails(notification.absenceId).subscribe(
      (data) => {
        let endDate = new Date(data?.endDate);
        if (data?.isFullDay) {
          endDate = new Date(endDate.setMinutes(endDate.getMinutes() + 330));
        }
        const eventInfo = {
          id: data?._id,
          userId: data?.userId,
          createdBy: data?.createdBy,
          status: data?.absenceStatus,
          allDay: data?.isFullDay,
          title: data?.absenceTypeName,
          titleId: data?.absenceTypeId,
          start: data?.startDate,
          end: endDate,
          actualEndDate: data?.endDate,
          backgroundColor: STATICS.ABSENCE_STATUS.filter((status) => status.status === data?.absenceStatus)[0].color,
          description: data?.reason,
          rejectedReason: data?.rejectedReason,
          reason: data?.reason,
          selectedUser: notification.userDetail,
          managerExists: data?.managerExists,
          logs: data?.logs,
        };
        this.openEventModal(eventInfo);
        this.hideLoader();
      },
      (err) => {
        this.showErrorToast(`Error in fetching records ${err.error.message}`);
        this.hideLoader();
      }
    );
  }

  openEventModal(eventInfo) {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '60%',
      height: '80%',
      data: {
        selectInfo: eventInfo,
        currentUser: this.currentUser,
        selectedUser: eventInfo.selectedUser,
        isManager: this.isManagerHR,
        display: true,
        absenceTypes: this.absenceTypes,
      },
      panelClass: 'scrollable-dialog', // Add the CSS class to enable scrolling
    };
    const dialogRef: MatDialogRef<UpdateEventComponent, any> = this.matDialog.open(UpdateEventComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getRecordsList();
    });
  }
}

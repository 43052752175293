import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VisionAppComponent } from '../../../../../core/base-classes/vision-app.component';
import { AppComponent } from '../../../../../app.component';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { STATICS } from '../../../../../core/config/static/static';
import { CalendarService } from '../calendar.service';
import { SupabaseService } from '../../../../../core/auth/supabase.service';

@Component({
  selector: 'app-update-event',
  templateUrl: './update-event.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  styleUrls: ['./update-event.component.scss'],
})
export class UpdateEventComponent extends VisionAppComponent implements OnInit {
  absenceCreationFrom: UntypedFormGroup;
  reason: any;
  dateTime: Date;
  leaveTypes = [];
  currentAbsenceStatus: any;
  previousAbsenceStatus: any;
  previousEndDate: any;
  currentAbsenceType: any;
  absenceStatus = STATICS.ABSENCE_STATUS;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public hideTime = false;
  public touchUi = false;
  public enableMeridian = true;
  public minDate = null;

  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public startDate = new FormControl(new Date());
  public endDate = new FormControl(new Date());
  public minEndDate = null;
  public userLevel = {
    admin: false,
    hr: false,
    manager: false,
    employee: true,
    reject: false,
    approve: false,
  };
  public leaveDetails: any;
  constructor(
    @Inject(AppComponent) appComponent: AppComponent,
    private _formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UpdateEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private calendarService: CalendarService,
    private _supabaseService: SupabaseService
  ) {
    super(appComponent);
    this.leaveTypes = this.data?.absenceTypes;
  }
  ngOnInit(): void {
    const result = this.absenceStatus.filter((type) => type.status === this.data?.selectInfo?.status);
    if (result.length > 0) {
      this.currentAbsenceStatus = result[0];
    }
    if (this.data?.selectInfo?.logs) {
      if (this.data?.selectInfo?.logs?.isFullDay) {
        const endDate = new Date(this.data?.selectInfo?.logs?.endDate);
        this.previousEndDate = endDate;
      }
      const result = this.absenceStatus.filter((type) => type.status === this.data?.selectInfo?.logs?.absenceStatus);
      if (result.length > 0) {
        this.previousAbsenceStatus = result[0];
      }
    }

    const result1 = this.leaveTypes.filter((type) => type._id === this.data?.selectInfo?.titleId);
    if (result1.length > 0) {
      this.currentAbsenceType = result1[0];
    }
    if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.LEAVE_BALANCE)) {
      this.showLoader();
      this.calendarService.userLeaveBalanceDetail(this.data.selectedUser.userId).subscribe(
        (data) => {
          this.leaveDetails = data;
          this.hideLoader();
        },
        (err) => {
          this.hideLoader();
          this.showErrorToast(`Error in fetching available leaves ${err.error.message}`);
        }
      );
    }
    if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'Admin', 'Admin')) {
      this.userLevel.admin = true;
    }
    if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'HR', 'HR')) {
      this.userLevel.hr = true;
    } else if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'Manager', 'Manager')) {
      this.userLevel.manager = true;
    } else if (this._supabaseService.hasPermissions(this.data?.currentUser?.permission, 'Employee', 'Employee')) {
      this.userLevel.employee = true;
      this.minDate = new Date(this.data?.selectInfo?.start);
    }
    if (this.userLevel.hr) {
      if (this.data?.selectInfo?.status === 1) {
        if (!this.data?.selectInfo?.managerExists) {
          this.userLevel.reject = true;
          this.userLevel.approve = true;
        }
      }
      if (this.data?.selectInfo?.status === 5) {
        // if (this.data?.selectInfo?.managerExists) {
        this.userLevel.reject = true;
        this.userLevel.approve = true;
        // }
      }
    } else if (this.userLevel.manager) {
      if (this.data?.selectInfo?.status === 1) {
        this.userLevel.reject = true;
        this.userLevel.approve = true;
      }
    } else if (!this.userLevel.hr && !this.userLevel.manager) {
      if (this.data?.selectInfo?.status === 1) {
        if (this.data?.selectInfo?.managerExists && this.data?.selectedUser?.userId !== this.data?.currentUser?.userId) {
          this.userLevel.reject = true;
          this.userLevel.approve = true;
        }
      }
    }
    this.reason = this.data?.selectInfo?.description;
    this.absenceCreationFrom = this._formBuilder.group({
      titleId: [this.data?.selectInfo?.titleId || '', [Validators.required]],
      title: [this.data?.selectInfo?.title],
      allDay: [this.data?.selectInfo?.allDay],
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      description: [this.data?.selectInfo?.description, Validators.required],
      deleteDescription: [''],
      absenceStatus: [this.data?.selectInfo?.status || ''],
      titleSearch: [''],
    });
    if (this.data?.selectInfo?.start) {
      if (this.data?.selectInfo?.status !== 1 || this.data?.selectedUser?.userId !== this.data?.currentUser?.userId) {
        this.startDate = new FormControl(new Date(this.data?.selectInfo?.start));
        // this.startDate.disable();
      } else {
        this.startDate = new FormControl(new Date(this.data?.selectInfo?.start));
      }
    }
    if (this.data?.selectInfo?.actualEndDate) {
      if (this.data?.selectInfo?.status !== 1 || this.data?.selectedUser?.userId !== this.data?.currentUser?.userId) {
        this.endDate = new FormControl(this.data.selectInfo.actualEndDate);
      } else {
        this.endDate = new FormControl(this.data.selectInfo.actualEndDate);
      }
    }
    this.onChangeStartDate(new Date(this.data?.selectInfo?.start));
    this.onChangeEndDate(new Date(this.data?.selectInfo?.actualEndDate));
  }

  onChangeStartDate(event) {
    this.minEndDate = event;
    this.absenceCreationFrom.controls['start'].setValue(new Date(event));
  }

  onChangeEndDate(event) {
    this.absenceCreationFrom.controls['end'].setValue(new Date(event));
  }

  onSubmit() {
    if (!this.absenceCreationFrom.invalid) {
      const eventValue = this.absenceCreationFrom.value;
      if (eventValue.start.getTime() < eventValue.end.getTime()) {
        this.showLoader();
        const data = {
          _id: this.data?.selectInfo?.id,
          absenceTypeId: eventValue.titleId,
          companyId: this.data?.currentUser?.companyId,
          isFullDay: eventValue.allDay,
          startDate: eventValue.start,
          endDate: eventValue.end,
          reason: eventValue.description,
          userId: this.data?.selectedUser?.userId,
        };
        if (this.data.isManager) {
          data['absenceStatus'] = eventValue.absenceStatus;
        }
        this.calendarService.update(data).subscribe(
          (data) => {
            this.hideLoader();
            this.showSuccessToast(`Record Updated!`);
            this.dialogRef.close({ status: true, record: data });
          },
          (err) => {
            this.hideLoader();
            this.showErrorToast(`Error in record updating ${err.error.message}`);
          }
        );
      } else {
        this.showWarningToast('Start date time should be less then end date');
      }
    } else if (this.absenceCreationFrom.invalid) {
      this.absenceCreationFrom.markAllAsTouched();
      this.showWarningToast(STATICS.ERRORS.FORMINVALID);
      return;
    }
  }

  cancelEvent(type) {
    if (type === 'remove') {
      // eslint-disable-next-line max-len
      if (
        this._supabaseService.hasPermissions(this.data?.currentUser?.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.DELETE_REQUEST)
      ) {
        this.showLoader();
        this.calendarService.deleteCancelRequest(this.data?.selectInfo?.id, this.reason).subscribe(
          (data) => {
            this.hideLoader();
            this.showSuccessToast(`Requested Deleted!`);
            this.dialogRef.close({ status: true, record: data });
          },
          (err) => {
            this.hideLoader();
            this.showErrorToast(`Error in record deleting ${err.error.message}`);
          }
        );
      } else {
        this.showErrorToast(STATICS.ERRORS.UNAUTHORIZED);
      }
    } else {
      const data = {
        id: '4',
      };
      this.dialogRef.close({ status: true, data: data, action: 'cancelRequest' });
    }
  }

  filterOptionsByName(options, value) {
    value = value.trim().toLowerCase();
    return options.filter((option) => {
      return option.name.toLowerCase().includes(value);
    });
  }

  filtersItem(event) {
    this.leaveTypes = this.filterOptionsByName(this.data?.absenceTypes, event);
  }

  reject() {
    if (this.reason !== undefined && this.reason !== null && this.reason !== '') {
      // eslint-disable-next-line max-len
      if (
        this._supabaseService.hasPermissions(this.data?.currentUser?.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.APPROVE_REJECT)
      ) {
        this.showLoader();
        this.calendarService.approveRejectRequest(this.data?.selectInfo?.id, 'reject', this.reason).subscribe(
          (data) => {
            this.hideLoader();
            this.showSuccessToast(`Requested Rejected!`);
            this.dialogRef.close({ status: true, record: data });
          },
          (err) => {
            this.hideLoader();
            this.showErrorToast(`Error in record deleting ${err.error.message}`);
          }
        );
      } else {
        this.showErrorToast(STATICS.ERRORS.UNAUTHORIZED);
      }
    } else {
      this.showErrorToast('Reason is required');
    }
  }

  approved() {
    // eslint-disable-next-line max-len
    if (
      this._supabaseService.hasPermissions(this.data?.currentUser?.permission, STATICS.MODULES.ABSENCE_REQUEST, STATICS.APIS.ABSENCE_REQUEST.APPROVE_REJECT)
    ) {
      this.showLoader();
      this.calendarService.approveRejectRequest(this.data?.selectInfo?.id, 'approve', this.reason).subscribe(
        (data) => {
          this.hideLoader();
          this.showSuccessToast(`Requested Approved!`);
          this.dialogRef.close({ status: true, record: data });
        },
        (err) => {
          this.hideLoader();
          this.showErrorToast(`Error in record deleting ${err.error.message}`);
        }
      );
    } else {
      this.showErrorToast(STATICS.ERRORS.UNAUTHORIZED);
    }
  }

  closeModel() {
    this.dialogRef.close({ status: false, record: null });
  }
}

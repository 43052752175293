import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from './users.types';
//Base service
import { BaseApiService } from '../../../../shared/services/API-service/base-service';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseApiService {
  // Local variables
  private _userCount: number;
  private _basicInfoCount: number;
  private _personalInfoCount: number;
  private _contractCount: number;

  // Observers
  private _users: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private _http: HttpClient) {
    super('users', _http);
  }

  //
  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  get userscount$() {
    return this._userCount;
  }

  getCurrentUser() {
    return this.get('currentUser');
  }

  getAll(filter: any): Observable<any> {
    return this.post('listing', filter);
  }

  details(filter: any): Observable<any> {
    return this.post('profileDetail', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  getRecord(userId): Observable<any> {
    return this.get(`${userId}/detail`).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  create(user: any) {
    return this.post('create', user).pipe(
      map((newUser) => {
        return newUser;
      })
    );
  }

  createBankDetails(userId: any, bankDetail: any) {
    return this.post(`${userId}/save-bank-detail`, bankDetail).pipe(
      map((newBankDetails) => {
        return newBankDetails;
      })
    );
  }

  createChildrenDetails(userId: any, childrenDetailsList: any) {
    return this.post(`${userId}/save-children-detail`, childrenDetailsList).pipe(
      map((newChildrenDetail) => {
        return newChildrenDetail;
      })
    );
  }

  createContactDetails(userId: any, contactDetailsList: any) {
    return this.post(`${userId}/save-emergency-contact-info`, contactDetailsList).pipe(
      map((newContactDetail) => {
        return newContactDetail;
      })
    );
  }

  createEducationDetails(userId: any, educationDetailsList: any) {
    return this.post(`${userId}/save-education-info`, educationDetailsList).pipe(
      map((newContactDetails) => {
        return newContactDetails;
      })
    );
  }

  createBasicInfo(userId: any, basicInfo: any) {
    return this.post(`${userId}/save-public-info`, basicInfo).pipe(
      map((newBasicInfo) => {
        return newBasicInfo;
      })
    );
  }

  createPersonalInfo(userId: any, personalInfo: any) {
    return this.post(`${userId}/save-private-info`, personalInfo).pipe(
      map((newPersonalInfo) => {
        return newPersonalInfo;
      })
    );
  }

  createContractInfo(userId: any, contractInfo: any) {
    return this.post(`${userId}/save-contract-detail`, contractInfo).pipe(
      map((newPersonalInfo) => {
        return newPersonalInfo;
      })
    );
  }

  createPayrollInfo(userId: any, payrollForm: any) {
    return this.post(`${userId}/save-payroll-detail`, payrollForm).pipe(
      map((newPayrollForm) => {
        return newPayrollForm;
      })
    );
  }

  createUserProfileInfo(userId: any, payrollForm: any) {
    return this.post(`${userId}/save-user-absence-info`, payrollForm).pipe(
      map((newUserProfileForm) => {
        return newUserProfileForm;
      })
    );
  }

  createUserHealthInfo(userId: any, healthForm: any) {
    return this.post(`${userId}/save-health-area-detail`, healthForm).pipe(
      map((newUserHealthForm) => {
        return newUserHealthForm;
      })
    );
  }

  resourceUpdate(inputs: any) {
    return this.post(`resourceUpload`, inputs).pipe(
      map((newResource) => {
        return newResource;
      })
    );
  }

  getSuggestedList(inputs: any) {
    return this.post(`filterList`, inputs).pipe(
      map((searchList) => {
        return searchList;
      })
    );
  }

  downloadCSV(inputs: any) {
    return this.post('userExport', inputs, { responseType: 'blob' });
  }

  deleteBankDetails(filter: any): Observable<any> {
    return this.post('archive-bank-account', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  deleteDependent(filter: any): Observable<any> {
    return this.post('archive-dependent', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  deleteEducationalDetail(filter: any): Observable<any> {
    return this.post('archive-education-details', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  deleteUserResources(filter: any): Observable<any> {
    return this.post('archive-resource', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }

  deleteEmergencyContact(filter: any): Observable<any> {
    return this.post('archive-emergency-contact', filter).pipe(
      tap((response) => {
        return response;
      })
    );
  }
}

/*
 *
 * @author      Jai Kalal <jai@go-techsoluation.com>
 * @package     Config
 *
 */
export const STATICS = Object.freeze({
  LIST: {
    GENDER: ['male', 'female', 'non binary'],
    ADDRESSPROOF: ['passport', 'registration certificate'],
    MARITIALSTATUS: [
      { id: 1, name: 'Single' },
      { id: 2, name: 'Married' },
    ],

    EMPLOYEESTATUS: [
      { _id: 0, display: 'In-Active' },
      { _id: 1, display: 'Active' },
      { _id: 2, display: 'On Boarding' },
      { _id: 3, display: 'Off Boarding' },
      { _id: 4, display: 'In Complete' },
      { _id: 5, display: 'Complete' },
    ],

    EDUCATIONLIST: [
      { _id: 1, display: 'Bachelor' },
      { _id: 2, display: 'Master' },
      { _id: 3, display: 'Diploma' },
      { _id: 4, display: 'Certification' },
      { _id: 5, display: 'BA' },
      { _id: 6, display: 'MA' },
      { _id: 7, display: 'AssocDegree' },
      { _id: 8, display: 'Post_Grad' },
    ],

    PERIODIN: [
      { value: 1, display: 'Bachelor' },
      { value: 2, display: 'Master' },
      { value: 3, display: 'Diploma' },
      { value: 4, display: 'Certification' },
    ],

    COMPANYSTATUS: [
      { value: 1, label: 'Active' },
      { value: 0, label: 'In-Active' },
    ],

    PERIODINLIST: ['days', 'weeks', 'months', 'No Probation'],
    NOTICEPERIOD: ['days', 'weeks', 'months'],
    DURATIONOFCONTRACT: ['days', 'weeks', 'months', 'Unlimited', 'year'],

    RELIGIOUSDENOMINATION: [
      { id: 1, name: 'Evangelical' },
      { id: 2, name: 'Catholic' },
    ],

    SALUTATIONS: ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'],

    CATEGORIES: [
      'Accidents and Disasters',
      'Business and Economics',
      'Culture Events and Celebrations',
      'Entertainment and Arts',
      'Environment and Nature',
      'Finance',
      'Human Interest',
      'Light news',
      'Military',
      'Politics',
      'Science and Tech',
      'Society',
      'Sport',
    ],

    CURRENCIES: [
      { id: '1', name: 'Euro', code: 'EUR', symbol: '€' },
      { id: '2', name: 'Angolan kwanza', code: 'AOA', symbol: 'Kz' },
      { id: '3', name: 'Indian rupee', code: 'INR', symbol: '₹' },
    ],

    TAXATION: ['Not applicable', 'Reduced', 'Exemption'],

    LABEL: ['Work', 'Personal', 'Other'],

    LANGUAGES: ['Arabic', 'English', 'Russian', 'Spanish'],

    COMPANYSIZES: [
      { key: 'small', value: 'S' },
      { key: 'medium', value: 'M' },
      { key: 'large', value: 'L' },
      { key: 'extraLarge', value: 'XL' },
    ],

    PLATFORMLIST: ['Social Media', 'Online', 'Broadcast'],

    SOCIALMEDIALIST: [
      'Facebook',
      'Twitter',
      'Instagram',
      'LinkedIn',
      'YouTube',
      'Pinterest',
      'Snapchat',
      'TikTok',
      'WhatsApp',
      'WeChat',
      'Telegram',
      'Reddit',
      'Tumblr',
      'Pinterest',
      'Flickr',
      'Vimeo',
      'SoundCloud',
      'Spotify',
      'Twitch',
      'Medium',
      'Quora',
    ],
    DEPARTMENTTYPE: [
      { id: 1, name: 'Top Level' },
      { id: 2, name: 'Sub-Department' },
    ],
    HEALTHINSURANCETYPE: ['statutory health insurance', 'private health insurance', 'international health insurance', 'company health insurance'],
    TAXCLASSTYPE: [
      { id: 1, name: 'regular' },
      { id: 2, name: 'reduce' },
      { id: 3, name: 'zero' },
    ],
    EMERGENCYCONTACTRELATION: [
      'Spouse',
      'Parent',
      'Sibling',
      'Adult child',
      'Domestic partner',
      'Other family members',
      'Close friend',
      'Roommate or housemate',
      'Neighbor',
      'Coworker or supervisor',
      'Friend',
      'Husband',
      'Mother',
      'Sister',
      'Friend',
      'Brother',
    ],
    JOBTYPE: [
      { _id: 1, name: 'primary' },
      { _id: 2, name: 'secondary' },
    ],
    COMPANYPENSION: [
      { _id: 1, name: 'yes' },
      { _id: 2, name: 'no' },
    ],
    EMPLOYEEMENTLIST: [
      { _id: '1', name: 'with in 1 month' },
      { _id: '3', name: 'with in 3 month' },
      { _id: '6', name: 'with in 6 month' },
      { _id: '4', name: 'custom' },
    ],
  },
  MODULES: {
    CONTACTROLE: 'Contact Role',
    LEADSTATUS: 'Lead Status',
    LEAD: 'Lead',
    CONTRACTTYPE: 'Contract Type',
    COSTCENTER: 'Cost Centre',
    DEPARTMENT: 'Department',
    FINANCIALYEAR: 'Financial year',
    HEALTHINSURANCE: 'Health Insurance',
    CURRENCYTYPE: 'Currency',
    PAYMENTTYPE: 'Payment Type',
    POSITION: 'Position',
    ROLE: 'Role',
    TAXMASTER: 'Tax Master',
    USER: 'User',
    USERTEMPLATE: 'User Template',
    COMPANYTYPE: 'CompanyType',
    COUNTRY: 'Country',
    LANGUAGE: 'Language',
    COMPANY: 'Company',
    RESOURCE: 'Resource',
    TENANTMASTER: 'System Master',
    BUSINESSTERRITORY: 'Lead Business Territory',
    JOBTITLE: 'Lead Job Title',
    PRODUCTINTEREST: 'Lead Product Interest',
    ACCOUNTSOURCE: 'Lead Source',
    SUBBUSINESSTERRITORY: 'Lead Sub Business Territory',
    LEADDEPARTMENT: 'Lead Department',
    LEADCOMPANYTYPE: 'Lead CompanyType',
    EMPLOYEE: 'Employee',
    HR: 'HR',
    MANAGER: 'Manager',
    ADMIN: 'Admin',
    BRANCH: 'Branch',
    OFFICELOCATION: 'Office Location',
    ABSENCE: 'Absence Type',
    ABSENCE_REQUEST: 'Absence Request',
    STATE: 'States',
    CITY: 'Cities',
    WORKER: 'Worker',
    COVERAGE: 'Coverage',
    STORY: 'Story',
    STORYBOOKING: 'Story Booking',
    TEMPLATEMASTER: 'Template Master',
    USERCONTRACT: 'User Contract',
    CRM_LEAD: 'Crm Lead',
    CRM_LEAD_CONTACT: 'Crm Lead Contact',
    CRM_LEAD_ACCOUNT: 'Crm Lead Account',
  },
  MODULES_LIST: [
    'Lead Status',
    'Lead Source',
    'Contact Role',
    'Lead Business Territory',
    'Lead Job Title',
    'Lead Product Interest',
    'Lead Sub Business Territory',
    'Lead Department',
    'Lead CompanyType',
    'Lead',
    'Department',
    'Role',
    'Payment Type',
    'Contract Type',
    'Tax Master',
    'Health Insurance',
    'Financial year',
    'Position',
    'Cost Centre',
    'User',
    'User Template',
    'Worker',
    'Coverage',
    'Story Booking',
  ],
  APIS: {
    CONTACTROLE: {
      LISTING: '/tenant/contact-role/listing',
      CREATE: '/tenant/contact-role/create',
      UPDATE: '/tenant/contact-role/update',
    },
    OFFICELOCATION: {
      LISTING: '/systems/officeLocation/listing',
      CREATE: '/systems/officeLocation/create',
      UPDATE: '/systems/officeLocation/update',
    },
    STATE: {
      LISTING: '/systems/states/listing',
      CREATE: '/systems/states/create',
      UPDATE: '/systems/states/update',
    },
    CITY: {
      LISTING: '/systems/cities/listing',
      CREATE: '/systems/cities/create',
      UPDATE: '/systems/cities/update',
    },
    LEADSTATUS: {
      LISTING: '/tenant/lead-status/listing',
      CREATE: '/tenant/lead-status/create',
      UPDATE: '/tenant/lead-status/update',
    },
    LEAD: {
      LISTING: '/leads/listing',
      CREATE: '/leads/create',
      UPDATE: '/leads/lead-detail',
      LEADINFO: '/leads/save-lead-detail',
      BUSINESSINFO: '/leads/save-business-detail',
      CONTACTINFO: '/leads/save-contact-detail',
      LEGALINFO: '/leads/save-legal-detail',
      FINANCIALINFO: '/leads/save-finance-detail',
      SYSTEMINFO: '/leads/save-system-detail',
      FILTERLISTING: '/leads/filter-listing',
      EMAILPHONE: '/leads/updateEmailPhoneNumber',
    },
    CONTRACTTYPE: {
      LISTING: '/tenant/contract-type/listing',
      CREATE: '/tenant/contract-type/create',
      UPDATE: '/tenant/contract-type/update',
    },
    COSTCENTER: {
      LISTING: '/tenant/cost-centres/listing',
      CREATE: '/tenant/cost-centres/create',
      UPDATE: '/tenant/cost-centres/update',
    },
    DEPARTMENT: {
      LISTING: '/tenant/departments/listing',
      CREATE: '/tenant/departments/create',
      UPDATE: '/tenant/departments/update',
      HIERARCHY: '/tenant/departments/filter-hierarchy',
    },
    FINANCIALYEAR: {
      LISTING: '/tenant/financial-year/listing',
      CREATE: '/tenant/financial-year/create',
      UPDATE: '/tenant/financial-year/update',
    },
    HEALTHINSURANCE: {
      LISTING: '/tenant/health-insurance/listing',
      CREATE: '/tenant/health-insurance/create',
      UPDATE: '/tenant/health-insurance/update',
    },
    PAYMENTTYPE: {
      LISTING: '/tenant/payment-types/listing',
      CREATE: '/tenant/payment-types/create',
      UPDATE: '/tenant/payment-types/update',
    },
    CURRENCYTYPE: {
      LISTING: '/tenant/currency/listing',
      CREATE: '/tenant/currency/create',
      UPDATE: '/tenant/currency/update',
    },
    POSITION: {
      LISTING: '/tenant/positions/listing',
      CREATE: '/tenant/positions/create',
      UPDATE: '/tenant/positions/update',
    },
    ROLE: {
      LISTING: '/roles/listing',
      CREATE: '/roles/create',
      UPDATE: '/roles/update',
      PERMISSION: '/roles/permission-listing',
      DEFAULTPERMISSION: '/roles/default-permission',
      SAVEPERMISSION: '/roles/save-permission',
    },
    TAXMASTER: {
      LISTING: '/tenant/tax-masters/listing',
      CREATE: '/tenant/tax-masters/create',
      UPDATE: '/tenant/tax-masters/update',
    },
    USER: {
      LISTING: '/users/listing',
      CREATE: '/users/create',
      UPDATE: '/users/:userId/detail',
      PUBLICINFO: '/users/:userId/save-public-info',
      PRIVATEINFO: '/users/:userId/save-private-info',
      CHILDRENDETAILS: '/users/:userId/save-children-detail',
      EMERGENCYCONTACTDETAILS: '/users/:userId/save-emergency-contact-info',
      PAYROLLINFO: '/users/:userId/save-payroll-detail',
      USERTEMPLATE: '/users/:userId/save-user-absence-info',
      BANKDETAILS: '/users/:userId/save-bank-detail',
      EDUCATIONINFO: '/users/:userId/save-education-info',
      CONTACTINFO: '/users/:userId/save-contract-detail',
      RESOURCEUPLOAD: '/resources/upload',
      RESOURCEDOWNLOAD: '/resources/download',
      RESOURCEPRIVIEW: '/resources/preview',
      FILTERLIST: '/users/filterList',
      PROFILEDETAILS: '/users/profileDetail',
      EXPORT: '/users/userExport',
      HEALTHAREA: '/users/:userId/save-health-area-detail',
    },
    USERTEMPLATE: {
      LISTING: '/tenant/user-profile/listing',
      CREATE: '/tenant/user-profile/create',
      UPDATE: '/tenant/user-profile/update',
      FILTER: '/tenant/user-profile/filter-list',
    },
    COMPANYTYPE: {
      LISTING: '/systems/company-types/listing',
      CREATE: '/systems/company-types/create',
      UPDATE: '/systems/company-types/update',
    },
    COUNTRY: {
      LISTING: '/systems/countries/listing',
      CREATE: '/systems/countries/create',
      UPDATE: '/systems/countries/update',
    },
    LANGUAGE: {
      LISTING: '/systems/language/listing',
      CREATE: '/systems/language/create',
      UPDATE: '/systems/language/update',
    },
    COMPANY: {
      LISTING: '/companies/listing',
      CREATE: '/companies/create',
      UPDATE: '/companies/update',
      STATUS: '/companies/update-status',
      FILTER: '/companies/filter-list',
      EXCELUSERUPLOAD: '/users/:companyId/read-xlsx',
    },
    MASTER: {
      LISTING: '/tenant/masters',
    },
    BUSINESSTERRITORY: {
      LISTING: '/leads/business-territory/listing',
      CREATE: '/leads/business-territory/create',
      UPDATE: '/leads/business-territory/update',
    },
    JOBTITLE: {
      LISTING: '/leads/job-title/listing',
      CREATE: '/leads/job-title/create',
      UPDATE: '/leads/job-title/update',
    },
    PRODUCTINTEREST: {
      LISTING: '/leads/product-interest/listing',
      CREATE: '/leads/product-interest/create',
      UPDATE: '/leads/product-interest/update',
    },
    ACCOUNTSOURCE: {
      LISTING: '/leads/account-source/listing',
      CREATE: '/leads/account-source/create',
      UPDATE: '/leads/account-source/update',
    },
    SUBBUSINESSTERRITORY: {
      LISTING: '/leads/subBusiness-territory/listing',
      CREATE: '/leads/subBusiness-territory/create',
      UPDATE: '/leads/subBusiness-territory/update',
    },
    LEADDEPARTMENT: {
      LISTING: '/leads/tenant-department/listing',
      CREATE: '/leads/tenant-department/create',
      UPDATE: '/leads/tenant-department/update',
    },
    LEADCOMPANYTYPE: {
      LISTING: '/leads/tenant-companyType/listing',
      CREATE: '/leads/tenant-companyType/create',
      UPDATE: '/leads/tenant-companyType/update',
    },
    BRANCH: {
      LISTING: '/companies/branch-listing',
      CREATE: '/companies/create-branch',
      UPDATE: '/companies/branch-update',
      DETAILS: '/companies/branch-detail',
      FILTER: '/companies/branch-filter-list',
      LINKING: '/companies/branch-linking-create',
      UNLINKING: '/companies/branch-un-link',
    },
    ABSENCE_TYPE: {
      FILTER: '/absence/absenceType/filter-list',
    },
    ABSENCE_REQUEST: {
      DEPARTMENT_FILTER_LIST: '/absence/absenceRequest/department-filterList',
      REQUEST_LIST: '/absence/absenceRequest/dateWiseAbsenceList',
      LEAVE_BALANCE: '/absence/absenceRequest/userLeaveBalanceDetail',
      DELETE_REQUEST: '/absence/absenceRequest/deleteCancelRequest',
      CREATE: '/absence/absenceRequest/create',
      APPROVE_REJECT: '/absence/absenceRequest/approveRejectRequest',
    },
    WORKER: {
      LISTING: '/erp/worker/listing',
      UPDATE: '/erp/worker/update',
      FINANCEUPDATE: '/erp/worker/update-finance-details',
      LISTINGFORBOOKING: '/erp/worker/worker-listing-forBooking',
      FINANCIALTRANSACTIONLISTING: '/erp/worker/listing-financial-transition',
      HISTORYLISTING: '/erp/worker/history/listing',
    },
    COVERAGE: {
      LISTING: '/erp/coverage/listing',
      UPDATE: '/erp/coverage/update',
      DETAIL: '/erp/coverage/:id/detail',
    },
    STORY: {
      UPDATE: '/erp/story/update',
      DETAIL: '/erp/story/:storyId/:coverageId/detail',
      PROPOSALDETAIL: '/erp/story/:_id/:workerId/proposal-detail',
    },
    STORYBOOKING: {
      DETAIL: '/erp/story-booking/worker-detail',
    },
  },
  ERRORS: {
    UNAUTHORIZED: 'Unauthorized attempt detected, Please contact to your administrator',
    NOUPDATEFOUND: 'Please make some changes for create, no found any changes',
    FORMINVALID: 'Please fill valid information',
  },
  SUCCESS: {
    CREATED: 'Record Created',
    UPDATED: 'Record Updated',
  },
  MASTERSTYPES: {
    DEPARTMENT: 1,
    EMPLOYMENT_TYPE: 2,
    POSITION: 3,
    COST_CENTRE: 4,
    ROLE: 5,
    USER: 6,
    HEALTH_INSURANCE: 7,
    PAYMENT_TYPE: 8,
    TAX_CLASS: 9,
    FINANCIAL_YEAR: 10,
    COUNTRY: 11,
    LANGUAGE: 12,
    LEAD_STATUS: 13,
    COMPANY_TYPE: 14,
    CONTACT_ROLE: 15,
    SPOKEN_LANGUAGE: 16,
    ACCOUNT_SOURCE: 17,
    JOB_TITLE: 18,
    TENANT_DEPARTMENT: 19,
    PRODUCT_INTEREST: 20,
    BUSINESS_TERRITORY: 21,
    CITY: 22,
    SUB_BUSINESS_TERRITORY: 23,
    SUPERVISOR: 24,
    TENANT_COMPANY_TYPE: 25,
    STATE: 28,
    OFFICE_LOCATION: 29,
    USER_PROFILE: 30,
    BRANCH_FILTER: 31,
    CURRENCY: 32,
  },
  PERMISSIONAPIS: {
    CONTACTROLE: {
      LISTING: 'contact.role.listing',
      UPDATE: 'contact.role.update',
    },
    OFFICELOCATION: {
      LISTING: 'office.location.listing',
      UPDATE: 'office.location.update',
    },
    CITY: {
      LISTING: 'cities.listing',
      UPDATE: 'cities.update',
    },
    STATE: {
      LISTING: 'states.listing',
      UPDATE: 'states.update',
    },
    LEADSTATUS: {
      LISTING: 'lead.status.listing',
      UPDATE: 'lead.status.update',
    },
    CONTRACTTYPE: {
      LISTING: 'contract.type.listing',
      UPDATE: 'contract.type.update',
    },
    COSTCENTER: {
      LISTING: 'cost.centre.listing',
      UPDATE: 'cost.centre.update',
    },
    DEPARTMENT: {
      LISTING: 'department.listing',
      UPDATE: 'department.update',
    },
    FINANCIALYEAR: {
      LISTING: 'financial.year.listing',
      UPDATE: 'financial.year.update',
    },
    HEALTHINSURANCE: {
      LISTING: 'health.insurance.listing',
      UPDATE: 'health.insurance.update',
    },
    PAYMENTTYPE: {
      LISTING: 'payment.type.listing',
      UPDATE: 'payment.type.update',
    },
    CURRENCYMASTER: {
      LISTING: 'currency.master.listing',
      UPDATE: 'currency.master.update',
    },
    POSITION: {
      LISTING: 'position.listing',
      UPDATE: 'position.update',
    },
    ROLE: {
      LISTING: 'role.listing',
      UPDATE: 'role.update',
    },
    TAXMASTER: {
      LISTING: 'tax.master.listing',
      UPDATE: 'tax.master.update',
    },
    COMPANYTYPE: {
      LISTING: 'company.type.listing',
      UPDATE: 'company.type.update',
    },
    COUNTRY: {
      LISTING: 'country.listing',
    },
    LANGUAGE: {
      LISTING: 'language.listing',
    },
    COMPANY: {
      LISTING: 'company.listing',
      UPDATE: 'company.update',
      DETAILS: 'company.detail',
      FILTER: '/companies/filter-list',
    },
    LEAD: {
      LISTING: 'lead.listing',
      LEADINFO: 'lead.update.lead.detail',
      BUSINESSINFO: 'lead.update.business.detail',
      CONTACTINFO: 'lead.update.contact.detail',
      LEGALINFO: 'lead.update.legal.detail',
      FINANCIALINFO: 'lead.update.finance.detail',
      SYSTEMINFO: 'lead.update.system.detail',
    },
    USER: {
      LISTING: 'user.listing',
      PUBLICINFO: 'user.update.public.info',
      PRIVATEINFO: 'user.update.private.info',
      CHILDRENDETAILS: 'user.update.children.info',
      EMERGENCYCONTACTDETAILS: 'user.update.emergency.contact.info',
      PAYROLLINFO: 'user.update.payroll.info',
      USERTEMPLATE: 'user.update.absence.info',
      BANKDETAILS: 'user.update.bank.info',
      EDUCATIONINFO: 'user.update.education.info',
      CONTACTINFO: 'user.update.contract.info',
      RESOURCEUPLOAD: 'user.resource.upload',
      PROFILEDETAIL: 'user.profile.detail',
      USERHEALTHAREA: 'user.update.health.area.info',
    },
    USERTEMPLATE: {
      LISTING: 'user.template.listing',
      CREATE: 'user.template.create',
      UPDATE: 'user.template.update',
      FILTER: 'user.template.filter.list',
    },
    BUSINESSTERRITORY: {
      LISTING: 'lead.business.territory.listing',
      UPDATE: 'lead.business.territory.update',
    },
    JOBTITLE: {
      LISTING: 'lead.job.title.listing',
      UPDATE: 'lead.job.title.update',
    },
    PRODUCTINTEREST: {
      LISTING: 'lead.product.interest.listing',
      UPDATE: 'lead.product.interest.update',
    },
    ACCOUNTSOURCE: {
      LISTING: 'lead.source.listing',
      UPDATE: 'lead.source.update',
    },
    SUBBUSINESSTERRITORY: {
      LISTING: 'lead.sub.business.territory.listing',
      UPDATE: 'lead.sub.business.territory.update',
    },
    LEADDEPARTMENT: {
      LISTING: 'lead.department.listing',
      UPDATE: 'lead.department.update',
    },
    LEADCOMPANYTYPE: {
      LISTING: 'lead.companytype.listing',
      UPDATE: 'lead.companytype.update',
    },
    BRANCH: {
      LISTING: 'branch.listing',
      CREATE: 'branch.create',
      UPDATE: 'branch.update',
      DETAILS: 'branch.detail',
      FILTER: 'branch.linking.filter.list',
      LINKING: 'branch.linking.create',
      UNLINKING: 'branch.unlinking',
      LINKEDLIST: 'branch.linked.listing',
    },
    WORKER: {
      LISTING: 'worker.listing.overview',
      UPDATE: 'update.worker.basic.and.contact.details',
      FINANCEUPDATE: 'update.worker.finance.details',
      LISTINGFORBOOKING: 'worker.listing.for.booking',
      FINANCIALTRANSACTIONLISTING: 'worker.financial.transaction.details.listing',
      HISTORYLISTING: 'worker.history.listing',
    },
    COVERAGE: {
      LISTING: 'listing.coverage',
      UPDATE: 'coverage.update',
      DETAIL: 'detail.coverage',
    },
    STORY: {
      UPDATE: 'update.story',
      DETAIL: 'detail.story',
      PROPOSALDETAIL: 'proposal.details',
    },
    STORYBOOKING: {
      DETAIL: 'workers.details',
    },
    TEMPLATEMASTER: {
      LISTING: 'template.master.listing',
      UPDATE: 'template.master.update',
      DEATIL: 'template.master.detail',
    },
    USERCONTRACT: {
      LISTING: 'user.contract.listing',
    },
  },
  LEADFILTER: {
    STATUS: 1,
    COMPANY_TYPE: 2,
    CONTRACT_TYPE: 3,
    COMPANY_SIZE: 4,
    CONTENT_INTEREST_CATEGORY: 5,
    COUNTRY: 6,
    CONTACT_LANGUAGE: 7,
    DEPARTMENT: 8,
    CONTACT_ROLE: 9,
    CURRENCY: 10,
    BUSINESS_UNIT: 11,
    PRODUCT_INTEREST: 12,
    CONTENT_INTEREST_TAGS: 13,
  },
  APIFORLOG: {
    EMAIL: '/leads/updateEmailPhoneNumber',
    BUSINESS: '/leads/save-business-detail',
    CONTACT: '/leads/save-contact-detail',
    LEGAL: '/leads/save-legal-detail',
    FINANCIAL: '/leads/save-finance-detail',
    LEAD: '/leads/save-lead-detail',
    SYSTEM: '/leads/save-system-detail',
  },
  ABSENCE_STATUS: [
    { status: 1, color: '#F29339', display: 'Pending' },
    { status: 3, color: '#000000', display: 'Cancel' },
    { status: 2, color: '#C0C0C0', display: 'Delete' },
    { status: 4, color: '#7B7A76', display: 'Manager Rejected' },
    { status: 5, color: '#00B8EF', display: 'Manager Approved' },
    { status: 6, color: '#ED1A25', display: 'HR Rejected' },
    { status: 7, color: '#20B14E', display: 'HR Approved' },
  ],
});
